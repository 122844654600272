import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { uploadProfile } from "../../../slices/axiosSlice";

function IndividualVerifyemail() {
  let token = "";
  let eToken = "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const CorpEverifyformdata = new FormData();
  const [emailvalidation, setEmailvalidation] = useState();
  const movetohome = () => {
    navigate("/");
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    token = url.searchParams.get("token");
    eToken = url.searchParams.get("eToken");
    const userAuthTracker = {
      id: 0,
      userId: "",
      email: "",
      uniqueToken: token,
      emailToken: eToken,
      authType: "",
      authMode: "",
      timeNow: null,
      expiryTime: 0,
      modifiedTime: null,
      status: "",
      count: 0,
    };
    CorpEverifyformdata.append(
      "userAuthTracker",
      JSON.stringify(userAuthTracker)
    );

    dispatch(
      uploadProfile({
        url: "common/validateIndividualEmailVerifyTokens",
        formData: CorpEverifyformdata,
      })
    )
      .unwrap()
      .then((response) => {
        setEmailvalidation(
          "Congratulations! Your email has been successfully verified. Documents will be verified by the admin team and your account will be activated. You will be notified through an email within next 5 business days.Thank you."
        );
      })
      .catch((error) => {
        setEmailvalidation("Token Expired or Invalid.Please try again");
      });
  }, []);
  return (
    <div className="fontfamilynca">
      <div
        className="d-flex justify-content-center "
        style={{
          backgroundColor: "LightGray",
          height: "100vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="col-md-8 p-5 ml-5 mt-5">
          <div className="card ">
            <div
              className="card-header"
              style={{
                background: "linear-gradient(270deg, #e09400 0%, #fdc24f 100%)",
              }}
            >
              <div className="d-flex justify-content-center">
                <img
                  src={require("./../../assets/icons/email.png")}
                  alt="check-icon"
                />
              </div>
              <div className="text-center font12 text-white fw-bold">
                DoAram - E-mail Verification
              </div>
            </div>

            <div className="card-body ">
              <p className="text-dark pt-2">{emailvalidation}</p>

              <p className="pt-2 text-dark  d-flex justify-content-center">
                For further clarification, reach us at &nbsp;
                <a href="mailto:info@doaram.com">info@doaram.com</a>
              </p>
            </div>
            <div className="card-footer">
              <div className="text-center font12 text-success fw-bold pt-3">
                <button
                  className="btn btn-success btn-sm"
                  type="button"
                  onClick={movetohome}
                >
                  <i className="fa fa-arrow-left"></i>&nbsp;Back To Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndividualVerifyemail;
