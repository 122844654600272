// Import createSlice from Redux Toolkit
import { createSlice } from '@reduxjs/toolkit';

// Initial state for the slice
const initialState = {
  bankDetails: {},
  contactInfo: {},
  coordinator: {},
  ngoProfile: {},
};

// Create the slice
export const micrositeProfileSlice = createSlice({
  name: 'micrositeProfile',
  initialState,
  reducers: {
    setMicrositeProfile: (state, action) => {
      // Assuming action.payload contains the entire microsite profile data
      const { bankDetails, contactInfo, coordinator, ngoProfile } = action.payload;
      state.bankDetails = bankDetails;
      state.contactInfo = contactInfo;
      state.coordinator = coordinator;
      state.ngoProfile = ngoProfile;
    },
    // You can add more reducers as needed
  },
});

// Export the action creators
export const { setMicrositeProfile } = micrositeProfileSlice.actions;

// Export the reducer
export default micrositeProfileSlice.reducer;
