import React, { useEffect, useState } from "react";
import UploadService from "../../service/file-upload.service";
import Nodata from "../../assets/icons/Nodonation.png";
import $ from "jquery";
import { Table, Tag, Pagination, Tabs } from "antd";

function NGO_Donation() {
  const [individualpage, setIndividualPage] = useState(1);
  const [individualsize, setIndividualSize] = useState(6);
  const [individualtotalElements, setIndividualTotalElements] = useState(0);
  const [individualdataSource, setIndividualDataSource] = useState([]);
  let individualtablelength = individualdataSource.length;
  const [corppage, setCorpPage] = useState(1);
  const [corpsize, setCorpSize] = useState(6);
  const [corptotalElements, setCorpTotalElements] = useState(0);
  const [corpdataSource, setCorpDataSource] = useState([]);
  let Corptablelength = corpdataSource.length;

  const IndividualhandlePageChange = (individualpage) => {
    setIndividualPage(individualpage);
  };
  const CorphandlePageChange = (corppage) => {
    setCorpPage(corppage);
  };
  const generateColor = (email) => {
    const colors = [
      "#f44336",
      "#2196f3",
      "#4caf50",
      "#ff9800",
      "#9c27b0",
      "#673ab7",
      "#ff5722",
    ];
    const index = email.charCodeAt(0) % colors.length;
    return colors[index];
  };

  const Donationcolumns = [
    {
      title: "Profile",
      dataIndex: "profile",
      width: 10,
      render: (text, record) => {
        const firstName = record.email.charAt(0).toUpperCase();
        const color = generateColor(record.email);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                backgroundColor: color,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 8,
              }}
            >
              <span style={{ color: "white" }}>{firstName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "User Name",
      dataIndex: "corpName",
      width: 50,
    },
    // {
    //   title: "Phone",
    //   width: 20,
    //   dataIndex: "phone",
    // },
    {
      title: "causeId",
      dataIndex: "causeId",
      width: 20,
    },
    // {
    //   title: "orderId",
    //   dataIndex: "orderId",
    //   width: 20,
    // },

    {
      title: "Date",
      dataIndex: "createdDate",
      width: 100,
      render: (text) => {
        const datePart = text.substr(0, 10);
        return datePart;
      },
    },
    {
      title: "Paid",
      width: 90,
      dataIndex: "donatedAmount",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "CCAvenue",
      width: 90,
      dataIndex: "gatewayCreditedAmount",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "DoAram",
      width: 90,
      dataIndex: "doaramPlatformFees",
      render: (text) => <span>&#x20B9; {text}</span>,
    },

    {
      title: "NGO",
      width: 90,
      dataIndex: "finalAmountAfterFees",
      render: (text) => <span>&#x20B9; {text}</span>,
    },

    {
      title: "Status",
      dataIndex: "status",
      width: 90,
      render: (text) => {
        if (text === "READY_FOR_FULFILLMENT") {
          return <Tag color="#F89880">Doaram Received</Tag>;
        }
        if (text === "FULFILLED") {
          return <Tag color="#198754">NGO Received</Tag>;
        }
      },
    },
  ];

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  const IndividualCauseDonationformData = new FormData();
  IndividualCauseDonationformData.append("userCred", JSON.stringify(userCred));
  IndividualCauseDonationformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  IndividualCauseDonationformData.append("ngoId", "");
  IndividualCauseDonationformData.append("causeId", "");
  IndividualCauseDonationformData.append("status", "FULFILLED,READY_FOR_FULFILLMENT");
  IndividualCauseDonationformData.append("page", individualpage - 1);
  IndividualCauseDonationformData.append("size", individualsize);

  const CorpCauseDonationformData = new FormData();
  CorpCauseDonationformData.append("userCred", JSON.stringify(userCred));
  CorpCauseDonationformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  CorpCauseDonationformData.append("ngoId", "");
  CorpCauseDonationformData.append("causeId", "");
  CorpCauseDonationformData.append("status", "FULFILLED,READY_FOR_FULFILLMENT");
  CorpCauseDonationformData.append("page", corppage - 1);
  CorpCauseDonationformData.append("size", corpsize);

  useEffect(() => {
    UploadService.create(
      "ngo/cause/individual/donations/get",
      IndividualCauseDonationformData
    ).then((response) => {
      setIndividualDataSource(
        response.data.data.donationUserDetailsPage.content
      );
      setIndividualTotalElements(
        response.data.data.donationUserDetailsPage.totalElements
      );
    });

    UploadService.create(
      "ngo/cause/corp/donations/get",
      CorpCauseDonationformData
    ).then((response) => {
      setCorpDataSource(response.data.data.corpDonationUserDetails.content);
      setCorpTotalElements(
        response.data.data.corpDonationUserDetails.totalElements
      );
    });
  }, [individualpage, corppage]);
  return (
    <>
      <div className="col-md-12 row fontfamilynca p-2">
        <div className="col-sm-6">
          <h6 className="pt-2 tableheading">Cause Donation Details:</h6>
        </div>
        <Tabs
          defaultActiveKey="1"
          centered
          bordered={false}
          type="card"
          large
          items={[
            {
              label: "Individual Donations",
              key: "1",
              children: (
                <section className="content ">
                  {individualtablelength > 0 && (
                    <div className="row">
                      <div className="mt-3">
                        <Table
                          columns={Donationcolumns}
                          className="custom-table"
                          dataSource={individualdataSource}
                          style={{ padding: "5px" }}
                          onChange={IndividualhandlePageChange}
                          bordered
                          scroll={{
                            x: 1200,
                          }}
                          pagination={false}
                        />
                        <Pagination
                          Current={individualpage}
                          pageSize={individualsize}
                          onChange={IndividualhandlePageChange}
                          total={individualtotalElements}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  )}
                  {individualtablelength == 0 && (
                    <div
                      className="d-flex justify-content-center"
                      style={{ height: "80vh" }}
                    >
                      <img
                        src={Nodata}
                        alt="Nodata"
                        className="alignimagecenter"
                      />
                    </div>
                  )}
                </section>
              ),
            },
            {
              label: "Corporate Donations",
              key: "2",
              children: (
                <section className="content">
                  {Corptablelength > 0 && (
                    <div className="row">
                      <div className="mt-2">
                        <Table
                          columns={Donationcolumns}
                          dataSource={corpdataSource}
                          style={{
                            padding: "5px",
                          }}
                          onChange={CorphandlePageChange}
                          bordered
                          pagination={false}
                          className="custom-table"
                          scroll={{
                            x: 1200,
                          }}
                        />
                        <Pagination
                          Current={corppage}
                          total={corptotalElements}
                          pageSize={corpsize}
                          onChange={CorphandlePageChange}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  )}
                  {Corptablelength == 0 && (
                    <div
                      className="d-flex justify-content-center"
                      style={{ height: "80vh" }}
                    >
                      <img
                        src={Nodata}
                        alt="Nodata"
                        className="alignimagecenter"
                      />
                    </div>
                  )}
                </section>
              ),
            },
          ]}
        />
      </div>
    </>
  );
}

export default NGO_Donation;
