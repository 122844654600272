import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Form, Input, Button, Upload, Tooltip, Select } from "antd";
import UploadService from "../../service/file-upload.service";
import { State, City } from "country-state-city";
import Loading from "../../assets/loading-circle.gif";
import $ from "jquery";

import {
  LinkedinOutlined,
  UploadOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

function IndividualSignUpSelectors() {
  const { Option } = Select;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [blur, setBlur] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  console.log(userCred);
  const validatePhoneNumber = (rule, value) => {
    const phoneNumberRegex = /^[0-9]{10}$/;
    if (!value || phoneNumberRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number!");
  };
  const onsubmit = (values) => {
    console.log(values);
    const IndividualformData = new FormData();

    const IndividualProfile = {
      id: "",
      name: values.name,
      profilePicture:
        values.profilePicture !== undefined
          ? values.profilePicture.file.name
          : "",
      description: values.description,
      designation: values.designation,
      comments: "",
      createdDate: null,
      updatedDate: null,
      createdBy: null,
      updatedBy: null,
    };
    const ContactInfo = {
      entityId: "",
      addressLine1: values.addressLine1,
      addressLine2: "",
      city: values.city,
      state: values.state,
      country: "India",
      pinCode: values.pinCode,
      email: values.email,
      phone: values.phone,
      website: values.website,
      facebook: values.facebook,
      instagram: values.instagram,
      linkedin: values.linkedin,
      twitter: values.twitter,
      otherlinks: "",
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };

    const bankDetails = {
      entityId: "",
      bankName: values.bankName,
      branchName: values.branchName,
      accountName: values.accountName,
      accountNumber: values.accountNumber,
      ifscCode: values.ifscCode,
      upiCode: values.upiCode,
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };
    IndividualformData.append("userCred", JSON.stringify(userCred));
    IndividualformData.append(
      "individualProfile",
      JSON.stringify(IndividualProfile)
    );
    IndividualformData.append("bankDetails", JSON.stringify(bankDetails));
    IndividualformData.append("contactInfo", JSON.stringify(ContactInfo));

    if (values.profilePicture !== undefined) {
      IndividualformData.append(
        "profilePicture",
        values.profilePicture.file.originFileObj
      );
    }

    UploadService.create("/individual/signup", IndividualformData)
      .then((response) => {
        setLoading(false);
        setBlur(false);
        if ((response.data.httpStatus = "OK")) {
          navigate("/emailsent");
          window.toastr.warning(
            "Profile creation request received successfully"
          );
          sessionStorage.removeItem("key");
          sessionStorage.clear();
          sessionStorage.setItem(
            "da_userCred",
            JSON.stringify(response.data.data.userCred)
          );
          sessionStorage.setItem(
            "da_authMapping",
            JSON.stringify(response.data.data.userRoleAuthMapping)
          );
        }
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          window.toastr.warning(
            "You have alredy created account with this ID,please try with new id"
          );
        }
        console.error("Error during API call:", error);
        // window.toastr.warning("An error occurred. Please try again later.");
        setLoading(false);
        setBlur(false);
      });
  };
  const handleStateChange = (value) => {
    setSelectedState(value);

    const selectedStateInfo = State.getStatesOfCountry("IN").find(
      (state) => state.name === value
    );
    if (selectedStateInfo) {
      const citiesData = City.getCitiesOfState(
        selectedStateInfo.countryCode,
        selectedStateInfo.isoCode
      ).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(citiesData);
    } else {
      setCities([]);
    }

    form.setFieldsValue({ city: "" });
    setSelectedCity("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  useEffect(() => {
    const indianStates = State.getStatesOfCountry("IN").map(
      (state) => state.name
    );
    setStates(indianStates);
  }, []);
  return (
    <>
      {loading && (
        <div className="loader">
          <img src={Loading} alt="Loading..." />
        </div>
      )}
      <div className={blur ? "blur" : ""}>
        <div className="col-md-12" style={{ marginLeft: "0px" }}>
          <div className="">
            <div className="col-md-12 row text-center">
              <h6 className="b">
                Fill in the details
                <br />
                <small className="text-danger steps b  text-warning">
                  * Denotes Mandatory fields.
                </small>
              </h6>
            </div>
            <Form
              onFinish={onsubmit}
              form={form}
              // onFinishFailed={showerror}
              scrollToFirstError={true}
              name="validateOnly"
              labelCol={{ span: 8 }}
              autoComplete="off"
            >
              <div className="col-md-12  glasscard  p-3">
                <div className="row">
                  <div
                    className="col-md-6"
                    style={{ borderRight: "1px solid #f3f3f3" }}
                  >
                    <fieldset>
                      <div className=" p-0">
                        <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                          <legend className="para b themeCol-orange mx-3">
                            Personal Information:{" "}
                          </legend>
                          <div className="row mb-3">
                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="name"
                                label="Name"
                                rules={[
                                  {
                                    required: true,
                                    message: "Name is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Enter the  Name"
                                />
                              </Form.Item>
                            </div>

                            <div className="upload">
                              <Form.Item
                                className=""
                                name="profilePicture"
                                label="ProfilePicture"
                              >
                                <Upload
                                  listType="picture"
                                  // beforeUpload={customBeforeUpload}
                                  maxCount={1}
                                  accept=".jpg,.jpeg,.png"
                                >
                                  <Tooltip title="Upload images of type jpg,jpeg,png and pdf Files only">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Tooltip>
                                </Upload>
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="designation"
                                label="Designation"
                                rules={[
                                  {
                                    required: true,
                                    message: "Designation is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Enter the  Designation"
                                />
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="description"
                                label="Description"
                                rules={[
                                  {
                                    required: true,
                                    message: "Description is required",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  //showCount
                                  style={{ height: 100, resize: "none" }}
                                  placeholder="Enter Description"
                                />
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="addressLine1"
                                label="Registered Address"
                                //colon={false}
                                rules={[
                                  {
                                    required: true,
                                    message: "Address is required",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  style={{ height: 100, resize: "none" }}
                                  placeholder="Registered Address"
                                />
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="state"
                                label="State"
                              >
                                <Select
                                  placeholder="Select State"
                                  onChange={handleStateChange}
                                  value={selectedState}
                                  showSearch
                                >
                                  {states.map((state) => (
                                    <Option key={state} value={state}>
                                      {state}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="city"
                                label="City"
                              >
                                <Select
                                  placeholder="Select City"
                                  onChange={handleCityChange}
                                  showSearch
                                  value={selectedCity}
                                  disabled={!selectedState}
                                >
                                  {cities.map((city) => (
                                    <Option key={city.value} value={city.value}>
                                      {city.label}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>

                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="pinCode"
                                label="Pincode"
                                rules={[
                                  {
                                    required: true,
                                    message: "Pincode is required",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Enter Your Pincode"
                                />
                              </Form.Item>
                            </div>
                            <legend className="para b themeCol-orange mx-3">
                              Contact Information:{" "}
                            </legend>
                            <div>
                              <Form.Item
                                className="para b themeCol-orange"
                                name="email"
                                label="Email"
                                rules={[
                                  {
                                    required: true,
                                    message: "Email is required",
                                  },
                                  {
                                    type: "email",
                                    message: "Enter valid email",
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  placeholder="Enter Your Email"
                                />
                              </Form.Item>
                            </div>
                            <div>
                              <Form.Item
                                name="phone"
                                label="Mobile"
                                rules={[
                                  {
                                    required: true,
                                    message: "phone number is required",
                                  },
                                  {
                                    validator: validatePhoneNumber,
                                  },
                                ]}
                              >
                                <Input
                                  size="medium"
                                  type="number"
                                  placeholder="Enter Mobile Numner"
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-md-6">
                    <fieldset>
                      <div className=" p-0">
                        <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                          <div>
                            <Form.Item
                              name="website"
                              label="Website"
                              rules={[
                                {
                                  type: "url",
                                  message: "please enter correct url",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                placeholder="Website if you already have."
                              />
                            </Form.Item>
                          </div>

                          <div>
                            <Form.Item
                              className=""
                              name="facebook"
                              label="Facebook"
                              rules={[
                                {
                                  type: "url",
                                  message: "please enter correct url",
                                },
                              ]}
                            >
                              <Input
                                addonBefore={<FacebookOutlined />}
                                placeholder="FaceBook ID"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="instagram"
                              label="Instagram"
                              rules={[
                                {
                                  type: "url",
                                  message: "please enter correct url",
                                },
                              ]}
                            >
                              <Input
                                addonBefore={<InstagramOutlined />}
                                placeholder="Instagram ID"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="linkedin"
                              label="Linkedin"
                              rules={[
                                {
                                  type: "url",
                                  message: "please enter correct url",
                                },
                              ]}
                            >
                              <Input
                                addonBefore={<LinkedinOutlined />}
                                placeholder="LinkedIn ID"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              className=""
                              name="twitter"
                              label="Twitter"
                              rules={[
                                {
                                  type: "url",
                                  message: "please enter correct url",
                                },
                              ]}
                            >
                              <Input
                                addonBefore={<TwitterOutlined />}
                                placeholder="Twitter ID"
                                rules={[
                                  {
                                    type: "url",
                                    message: "please enter correct url",
                                  },
                                ]}
                              />
                            </Form.Item>
                          </div>

                          {/* ************Bank Details************ */}
                          <legend className="para b themeCol-orange">
                            Banking Information:{" "}
                          </legend>
                          <div>
                            <Form.Item
                              name="bankName"
                              label=" Bank Name"
                              rules={[
                                {
                                  required: true,
                                  message: " Bank name is required",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                placeholder="Your Bank Name"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="accountName"
                              label="Account Name"
                              rules={[
                                {
                                  required: true,
                                  message: " Account  name is required",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                placeholder="Your Account Name"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="branchName"
                              label="Branch Name"
                              rules={[
                                {
                                  required: true,
                                  message: " Branch name is required",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                placeholder="Your Branch Name"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="accountNumber"
                              label=" Account Number"
                              rules={[
                                {
                                  required: true,
                                  message: "Account Number is required",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                type="number"
                                placeholder="Your  Account Number"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="ifscCode"
                              label="IFSC Code"
                              rules={[
                                {
                                  required: true,
                                  message: "IFSC Code is required",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                placeholder="Your IFSC Code"
                              />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="upiCode"
                              label=" UPI Code"
                              rules={[
                                {
                                  required: true,
                                  message: "  UPI Code is required",
                                },
                              ]}
                            >
                              <Input
                                size="medium"
                                placeholder="Your  UPI Code"
                              />
                            </Form.Item>
                          </div>

                          <div className="row">
                            <div className="row">
                              <div className="col text-end">
                                <NavLink to="/individualsignup">
                                  <Button
                                    name="signupback"
                                    className="btnnca"
                                    style={{ width: "90px" }}
                                  >
                                    {" "}
                                    <i className="fa fa-arrow-left mx-2"></i>
                                    Back
                                  </Button>
                                </NavLink>
                              </div>
                              &nbsp;&nbsp;
                              <div className="col">
                                <Button
                                  htmlType="submit"
                                  className="btnnca btn-gold signupbtn"
                                  style={{ width: "90px" }}
                                >
                                  Submit{" "}
                                  <i className="fa fa-arrow-right mx-1"></i>
                                </Button>
                                {/* </NavLink> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
export default IndividualSignUpSelectors;
