import React, { useEffect, useState } from "react";
import UploadService from "../../service/file-upload.service";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Tabs,
  message,
  Modal,
} from "antd";
import { State, City } from "country-state-city";
import { useNavigate } from "react-router-dom";
import {
  
  LinkedinOutlined,
  UploadOutlined,
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  LinkOutlined,
} from "@ant-design/icons";
const { Option } = Select;

function Additionalinfo() {
  let token = "";
  let eToken = "";

  const getImageFormdata = new FormData();
  const [fileName, setfileName] = useState();
  const [profileData, setProfiledata] = useState();
  const [userCred, setUserCred] = useState();
  const [userRoleAuthMapping, setUserRoleAuthMapping] = useState();

  const [form] = Form.useForm();

  let ngoProfile = null;
  const navigate = useNavigate();

  const OPTIONS = [
    "Elderly",
    "Animals",
    "Children",
    "Cultural Heritage",
    "Education",
    "Employment",
    "Environment",
    "Disaster Relief Works",
    "Others",
    "Women Empowerment",
    "Community Mobilization",
    "Rural Development",
    "Sustainable Livelihood",
    "Differently Abled",
    "Advocacy",
  ];
  const [selectedItems, setSelectedItems] = useState([]);
  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const callImageAPI = (filename) => {
    getImageFormdata.append("userCred", JSON.stringify(userCred));
    getImageFormdata.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    getImageFormdata.append("ngoProfile", JSON.stringify(profileData));
    setfileName(filename);
    getImageFormdata.append("fileName", filename);

    UploadService.filedownload("/ngo/profile/getImage", getImageFormdata)

      .then((response) => {
        downloadBlob(response.data, filename);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function downloadBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);

    if (blob.type.startsWith("image")) {
      window.open(url, "_blank");
    } else if (blob.type === "application/pdf") {
      window.open(url, "_blank");
    }
    else {
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    window.URL.revokeObjectURL(url);
  }

  const [logoFilename, setlogoFilename] = useState("");
  const [cert12aFilename, setcert12aFilename] = useState("");

  const [microSiteFilename1, setmicroSiteFilename1] = useState("");
  const [microSiteFilename2, setmicroSiteFilename2] = useState("");
  const [microSiteFilename3, setmicroSiteFilename3] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [certIncorpRocFilename, setcertIncorpRocFilename] = useState("");
  const [pancardFilename, setpancardFilename] = useState("");
  const [regTrustDeedFilename, setregTrustDeedFilename] = useState("");
  const [moaAoaFilename, setmoaAoaFilename] = useState("");
  const [tanFilename, settanFilename] = useState("");
  const [csrForm1Filename, setcsrForm1Filename] = useState("");
  const [cert80gFilename, setcert80gFilename] = useState("");
  const [certFcraFilename, setcertFcraFilename] = useState("");
  const [micrositeFilename, setMicrositeFilename] = useState("");
  const [itrFilename, setItrFilename] = useState("");
  const [form10BFilename, setForm10BFilename] = useState("");
  const [fcraFilename, setFcraFilename] = useState("");
  const [error, setError] = useState(false);

  const [itrFormFilename1, setitrFormFilename1] = useState("");
  const [itrFormFilename2, setitrFormFilename2] = useState("");
  const [itrFormFilename3, setitrFormFilename3] = useState("");

  const [fcraReturnsFilename1, setfcraReturnsFilename1] = useState("");
  const [fcraReturnsFilename2, setfcraReturnsFilename2] = useState("");
  const [fcraReturnsFilename3, setfcraReturnsFilename3] = useState("");

  const [form10BFilename1, setform10BFilename1] = useState("");
  const [form10BFilename2, setform10BFilename2] = useState("");
  const [form10BFilename3, setform10BFilename3] = useState("");

  const islogoEmpty = logoFilename.trim().length === 0;
  const iscert12aEmpty = cert12aFilename.trim().length === 0;
  const ismicrositeEmpty = micrositeFilename.trim().length === 0;
  const isitrFormEmpty = itrFilename.trim().length === 0;
  const isform10BEmpty = form10BFilename.trim().length === 0;
  const isfcraEmpty = fcraFilename.trim().length === 0;

  const iscertIncorpRocEmpty = certIncorpRocFilename.trim().length === 0;
  const ispancardEmpty = pancardFilename.trim().length === 0;
  const isregTrustDeedEmpty = regTrustDeedFilename.trim().length === 0;
  const ismoaAoaEmpty = moaAoaFilename.trim().length === 0;
  const istanEmpty = tanFilename.trim().length === 0;
  const iscsrForm1Empty = csrForm1Filename.trim().length === 0;
  const iscert80gEmpty = cert80gFilename.trim().length === 0;
  const iscertFcraEmpty = certFcraFilename.trim().length === 0;

  const ismicroSiteEmpty1 = microSiteFilename1.trim().length === 0;
  const ismicroSiteEmpty2 = microSiteFilename2.trim().length === 0;
  const ismicroSiteEmpty3 = microSiteFilename3.trim().length === 0;

  const isitrFormEmpty1 = itrFormFilename1.trim().length === 0;
  const isitrFormEmpty2 = itrFormFilename2.trim().length === 0;
  const isitrFormEmpty3 = itrFormFilename3.trim().length === 0;

  const isform10BEmpty1 = form10BFilename1.trim().length === 0;
  const isform10BEmpty2 = form10BFilename2.trim().length === 0;
  const isform10BEmpty3 = form10BFilename3.trim().length === 0;

  const isfcraReturnsEmpty1 = fcraReturnsFilename1.trim().length === 0;
  const isfcraReturnsEmpty2 = fcraReturnsFilename2.trim().length === 0;
  const isfcraReturnsEmpty3 = fcraReturnsFilename3.trim().length === 0;

  useEffect(() => {
    const indianStates = State.getStatesOfCountry("IN").map(
      (state) => state.name
    );
    setStates(indianStates);
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    token = url.searchParams.get("token");
    eToken = url.searchParams.get("eToken");
    const userAuthTracker = {
      id: 0,
      userId: "",
      email: "",
      uniqueToken: token,
      emailToken: eToken,
      authType: "AccountCreationNeedAdditionalInfo",
      authMode: "emailLink",
      timeNow: null,
      expiryTime: "",
      modifiedTime: null,
      status: "",
      count: 0,
    };
    const AdditionalinfoformData = new FormData();
    AdditionalinfoformData.append(
      "userAuthTracker",
      JSON.stringify(userAuthTracker)
    );

   
    UploadService.create(
      "ngo/validateEmailVerifyTokens/getNGOProfile",
      AdditionalinfoformData
    )
      .then((response) => {
        setUserCred(response.data.data.userCred);
        setUserRoleAuthMapping(response.data.data.userRoleAuthMapping);
        form.setFieldsValue({
          name: response.data.data.ngoProfile.name,
          category: response.data.data.ngoProfile.category,
          about: response.data.data.ngoProfile.about,
          history: response.data.data.ngoProfile.history,
          vision: response.data.data.ngoProfile.vision,
          mission: response.data.data.ngoProfile.mission,
          geoFocus: response.data.data.ngoProfile.geoFocus,
          staticWebPageLink: response.data.data.ngoProfile.staticWebPageLink,
          certIncorpRoc: response.data.data.ngoProfile.certIncorpRoc,
          moaAoa: response.data.data.ngoProfile.moaAoa,
          regTrustDeed: response.data.data.ngoProfile.regTrustDeed,
          pan: response.data.data.ngoProfile.pan,
          tan: response.data.data.ngoProfile.tan,
          csrForm1: response.data.data.ngoProfile.csrForm1,
          cert12a: response.data.data.ngoProfile.cert12a,
          cert80g: response.data.data.ngoProfile.cert80g,
          certFcra: response.data.data.ngoProfile.certFcra,
          itrForm: response.data.data.ngoProfile.itrForm,
          form10B: response.data.data.ngoProfile.form10B,
          fcraReturns: response.data.data.ngoProfile.fcraReturns,
          logo: response.data.data.ngoProfile.logo,
          micrositeImages: response.data.data.ngoProfile.micrositeImages,

          addressLine1: response.data.data.contactInfo.addressLine1,
          city: response.data.data.contactInfo.city,
          state: response.data.data.contactInfo.state,
          country: response.data.data.contactInfo.country,
          pinCode: response.data.data.contactInfo.pinCode,
          email: response.data.data.contactInfo.email,
          phone: response.data.data.contactInfo.phone,
          website: response.data.data.contactInfo.website,
          facebook: response.data.data.contactInfo.facebook,
          instagram: response.data.data.contactInfo.instagram,
          linkedin: response.data.data.contactInfo.linkedin,
          twitter: response.data.data.contactInfo.twitter,
          otherlinks: response.data.data.contactInfo.otherlinks,

          coordinatorName: response.data.data.coordinator.coordinatorName,
          coordinatorEmail: response.data.data.coordinator.coordinatorEmail,
          coordinatorPhone: response.data.data.coordinator.coordinatorPhone,

          bankName: response.data.data.bankDetails.bankName,
          branchName: response.data.data.bankDetails.branchName,
          accountName: response.data.data.bankDetails.accountName,
          accountNumber: response.data.data.bankDetails.accountNumber,
          ifscCode: response.data.data.bankDetails.ifscCode,
          upiCode: response.data.data.bankDetails.upiCode,
        });

        setProfiledata(response.data.data.ngoProfile);
        setcertIncorpRocFilename(response.data.data.ngoProfile.certIncorpRoc);
        setmoaAoaFilename(response.data.data.ngoProfile.moaAoa);
        setpancardFilename(response.data.data.ngoProfile.pan);
        setlogoFilename(response.data.data.ngoProfile.logo);
        setcert12aFilename(response.data.data.ngoProfile.cert12a);
        setregTrustDeedFilename(response.data.data.ngoProfile.regTrustDeed);
        setmoaAoaFilename(response.data.data.ngoProfile.moaAoa);
        setcertFcraFilename(response.data.data.ngoProfile.certFcra);
        settanFilename(response.data.data.ngoProfile.tan);
        setcert80gFilename(response.data.data.ngoProfile.cert80g);
        setcsrForm1Filename(response.data.data.ngoProfile.csrForm1);
        setMicrositeFilename(response.data.data.ngoProfile.micrositeImages);
        setItrFilename(response.data.data.ngoProfile.itrForm);
        setForm10BFilename(response.data.data.ngoProfile.form10B);
        setFcraFilename(response.data.data.ngoProfile.fcraReturns);

        const itrFormimageNames = response.data.data.ngoProfile.itrForm;
        const itrformindividualimage = itrFormimageNames.split(",");
        if (itrformindividualimage[0]) {
          setitrFormFilename1(itrformindividualimage[0]);
        }
        if (itrformindividualimage[1]) {
          setitrFormFilename2(itrformindividualimage[1]);
        }
        if (itrformindividualimage[2]) {
          setitrFormFilename3(itrformindividualimage[2]);
        }
        const micrositeNames = response.data.data.ngoProfile.micrositeImages;
        const micrositeindividualimage = micrositeNames.split(",");
        if (micrositeindividualimage[0]) {
          setmicroSiteFilename1(micrositeindividualimage[0]);
        }
        if (micrositeindividualimage[1]) {
          setmicroSiteFilename2(micrositeindividualimage[1]);
        }
        if (micrositeindividualimage[2]) {
          setmicroSiteFilename3(micrositeindividualimage[2]);
        }

        const fcraNames = response.data.data.ngoProfile.fcraReturns;
        const fcraindividualimage = fcraNames.split(",");
        if (fcraindividualimage[0]) {
          setfcraReturnsFilename1(fcraindividualimage[0]);
        }
        if (fcraindividualimage[1]) {
          setfcraReturnsFilename2(fcraindividualimage[1]);
        }
        if (fcraindividualimage[2]) {
          setfcraReturnsFilename3(fcraindividualimage[2]);
        }
        const form10BNames = response.data.data.ngoProfile.form10B;
        const form10Bindividualimage = form10BNames.split(",");
        if (form10Bindividualimage[0]) {
          setform10BFilename1(form10Bindividualimage[0]);
        }
        if (form10Bindividualimage[1]) {
          setform10BFilename2(form10Bindividualimage[1]);
        }
        if (form10Bindividualimage[2]) {
          setform10BFilename3(form10Bindividualimage[2]);
        }
      })
      .catch((error) => {
        setError(true);
      });
  }, []);
  const handleStateChange = (value) => {
    setSelectedState(value);

    const selectedStateInfo = State.getStatesOfCountry("IN").find(
      (state) => state.name === value
    );
    if (selectedStateInfo) {
      const citiesData = City.getCitiesOfState(
        selectedStateInfo.countryCode,
        selectedStateInfo.isoCode
      ).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(citiesData);
    } else {
      setCities([]);
    }

    form.setFieldsValue({ city: "" }); // Clear selected city when state changes
    setSelectedCity("");
  };
  const handleCityChange = (value) => {
    setSelectedCity(value);
    // Do something with the selected city, if needed
  };
  const onsubmit = (values) => {

    const formDataWithFiles = new FormData();
    ngoProfile = {
      id: userCred.associatedEntity,
      name: values.name,
      category: values.category.toString(),
      about: values.about,
      history: values.history,
      vision: values.vision,
      mission: values.mission,
      geoFocus: values.geoFocus,
      staticWebPageLink: "",
      membership: "",
      certIncorpRoc:
        values.certIncorpRoc.file != undefined
          ? values.certIncorpRoc.file.name
          : cert12aFilename,
      moaAoa:
        values.moaAoa.file != undefined
          ? values.moaAoa.file.name
          : moaAoaFilename,
      regTrustDeed:
        values.regTrustDeed.file != undefined
          ? values.regTrustDeed.file.name
          : regTrustDeedFilename,

      pan:
        values.pan.file != undefined ? values.pan.file.name : pancardFilename,
      tan: values.tan.file != undefined ? values.tan.file.name : tanFilename,
      csrForm1:
        values.csrForm1.file != undefined
          ? values.csrForm1.file.name
          : csrForm1Filename,
      cert12a:
        values.cert12a.file != undefined
          ? values.cert12a.file.name
          : cert12aFilename,
      cert80g:
        values.cert80g.file != undefined
          ? values.cert80g.file.name
          : cert80gFilename,
      certFcra:
        values.certFcra.file != undefined
          ? values.certFcra.file.name
          : certFcraFilename,

      logo:
        values.logo.file != undefined ? values.logo.file.name : logoFilename,

      micrositeImages:
        values.micrositeImages.file != undefined
          ? values.micrositeImages.file.name
          : micrositeFilename,

      itrForm:
        values.itrForm.file != undefined
          ? values.itrForm.file.name
          : itrFilename,

      form10B:
        values.form10B.file != undefined
          ? values.form10B.file.name
          : form10BFilename,
      fcraReturns:
        values.fcraReturns.file != undefined
          ? values.fcraReturns.file.name
          : fcraFilename,
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };
    const contactInfo = {
      entityId: userCred.associatedEntity,
      addressLine1: values.addressLine1,
      addressLine2: "",
      city: values.city,
      state: values.state,
      country: "India",
      pinCode: values.pincode,
      email: values.email,
      phone: values.phone,
      website: values.website,
      facebook: values.facebook,
      instagram: values.instagram,
      linkedin: values.linkedin,
      twitter: values.twitter,
      otherlinks: "", //For now dont add anything to it
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };

    const ngoCoordinator = {
      entityId: userCred.associatedEntity,
      coordinatorEmail: values.coordinatorEmail,
      coordinatorName: values.coordinatorName,
      coordinatorPhone: values.coordinatorPhone,
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };

    const bankDetail = {
      entityId: userCred.associatedEntity,
      bankName: values.bankName,
      branchName: values.branchName,
      accountName: values.accountName,
      accountNumber: values.accountNumber,
      ifscCode: values.ifscCode,
      upiCode: values.upiCode,
      createdBy: "",
      createdDate: "",
      updatedBy: "",
      updatedDate: "",
    };

    formDataWithFiles.append("userCred", JSON.stringify(userCred));
    formDataWithFiles.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    formDataWithFiles.append("ngoProfile", JSON.stringify(ngoProfile));
    formDataWithFiles.append("contactInfo", JSON.stringify(contactInfo));
    formDataWithFiles.append("ngoCoordinator", JSON.stringify(ngoCoordinator));
    formDataWithFiles.append("bankDetails", JSON.stringify(bankDetail));

    if (values.certIncorpRoc.file != undefined) {
      formDataWithFiles.append(
        "certIncorpRoc",
        values.certIncorpRoc.file.originFileObj
      );
    }
    if (values.moaAoa.file != undefined) {
      formDataWithFiles.append("moaAoa", values.moaAoa.file.originFileObj);
    }
    if (values.regTrustDeed.file != undefined) {
      formDataWithFiles.append(
        "regTrustDeed",
        values.regTrustDeed.file.originFileObj
      );
    }
    if (values.pan.file != undefined) {
      formDataWithFiles.append("pan", values.pan.file.originFileObj);
    }
    if (values.tan.file != undefined) {
      formDataWithFiles.append("tan", values.tan.file.originFileObj);
    }
    if (values.csrForm1.file != undefined) {
      formDataWithFiles.append("csrForm1", values.csrForm1.file.originFileObj);
    }
    if (values.cert12a.file != undefined) {
      formDataWithFiles.append("cert12a", values.cert12a.file.originFileObj);
    }

    if (values.cert80g.file != undefined) {
      formDataWithFiles.append("cert80g", values.cert80g.file.originFileObj);
    }
    if (values.certFcra.file != undefined) {
      formDataWithFiles.append("certFcra", values.certFcra.file.originFileObj);
    }

    if (values.logo.file != undefined) {
      formDataWithFiles.append("logo", values.logo.file.originFileObj);
    }

    if (values.micrositeImages.file != undefined) {
      values.micrositeImages.fileList.forEach((file) => {
        formDataWithFiles.append("micrositeImages", file.originFileObj);
      });
    }

    if (values.itrForm.file != undefined) {
      values.itrForm.fileList.forEach((file) => {
        formDataWithFiles.append("itrForm", file.originFileObj);
      });
    }
    if (values.form10B.file != undefined) {
      values.form10B.fileList.forEach((file) => {
        formDataWithFiles.append("form10B", file.originFileObj);
      });
    }
    if (values.fcraReturns.file != undefined) {
      values.fcraReturns.fileList.forEach((file) => {
        formDataWithFiles.append("fcraReturns", file.originFileObj);
      });
    }

    UploadService.create(
      "ngo/additionalInfo/updateProfile",
      formDataWithFiles
    ).then((response) => {
      let logSts = response.data.message.messageType;

      if (logSts == "Success") {
        setIsModalOpen(true);
      } else {
        window.toastr.warning(
          response.data.message.messageDetails[0].responseMessage
        );
      }
    });
  };
  const handleOk = () => {
    navigate("/");
  };
  return (
    <>
    
      {!error && (
        <>
          <div className="col-md-12 row fontfamilynca">
            <div className="col-md-12" style={{ marginLeft: "0px" }}>
              <div className="">
                <div className="row text-center">
                  <h6 className="b">
                    Fill in the details of your NGO
                    <br />
                    <small className="text-danger steps b  text-warning">
                      * Denotes Mandatory fields.
                    </small>
                  </h6>
                </div>
                <Form
                  autoComplete="off"
                  labelCol={{ span: 8 }}
                  form={form}
                  onFinish={onsubmit}
                >
                  <div className="col-md-12 glasscard m-3 p-3">
                    <div className="row">
                      <div
                        className="col-md-6"
                        style={{ borderRight: "1px solid #f3f3f3" }}
                      >
                        <fieldset>
                          <div className=" p-0">
                            <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                              {/* Name of NGO*/}
                              <legend className="para b themeCol-orange mx-3">
                                NGO Information:{" "}
                              </legend>
                              <div className="mt-4">
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name="name"
                                  label="NGO Name"
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Name of  the NGO"
                                  />
                                </Form.Item>
                              </div>

                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name="category"
                                  label="Category"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Category is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Select
                                    mode="multiple"
                                    placeholder="--NGO Category--"
                                    value={selectedItems}
                                    onChange={setSelectedItems}
                                    style={{
                                      width: "100%",
                                    }}
                                    options={filteredOptions.map((item) => ({
                                      value: item,
                                      label: item,
                                    }))}
                                  ></Select>
                                </Form.Item>
                              </div>
                              <div className="mt-4">
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name="addressLine1"
                                  label="Address"
                                  rules={[
                                    {
                                      required: true,
                                      message: " Address is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input.TextArea
                                    //showCount
                                    style={{ height: 100, resize: "none" }}
                                    placeholder="Registered Address"
                                  />
                                </Form.Item>
                              </div>

                              <div>
                                <Form.Item
                                  className="para b themeCol-orange da_state"
                                  name="state"
                                  label="State"
                                  rules={[
                                    {
                                      required: true,
                                      message: "State is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Select
                                    placeholder="Select State"
                                    onChange={handleStateChange}
                                    value={selectedState}
                                  >
                                    {states.map((state) => (
                                      <Option key={state} value={state}>
                                        {state}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange da_cities"
                                  name="city"
                                  label="City"
                                  rules={[
                                    {
                                      required: true,
                                      message: "City is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Select
                                    placeholder="Select City"
                                    onChange={handleCityChange}
                                    value={selectedCity}
                                    disabled={!selectedState}
                                  >
                                    {cities.map((city) => (
                                      <Option
                                        key={city.value}
                                        value={city.value}
                                      >
                                        {city.label}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>

                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name="pinCode"
                                  label="PinCode"
                                  rules={[
                                    {
                                      required: true,
                                      message: "PinCode is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input
                                    size="medium"
                                    placeholder="PinCode of NGO"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name="email"
                                  label="Email"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Email is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Email of NGO"
                                  />
                                </Form.Item>
                              </div>

                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name="phone"
                                  label="Phone"
                                  rules={[
                                    {
                                      required: true,
                                      message: "contact number is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input
                                    addonBefore="IN(+91)"
                                    size="medium"
                                    placeholder="Contact number of NGO"
                                  />
                                </Form.Item>
                              </div>

                              <div>
                                <Form.Item
                                  className="para b themeCol-orange"
                                  name="website"
                                  label="Website"
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Website URL"
                                  />
                                </Form.Item>
                              </div>

                              <div className="upload">
                                {islogoEmpty ? (
                                  <Form.Item
                                    className=""
                                    name="logo"
                                    label="Logo"
                                  >
                                    <Upload maxCount={1} listType="picture">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <>
                                    <Form.Item
                                      className=""
                                      name="logo"
                                      label="Logo"
                                    >
                                      <Upload maxCount={1} listType="picture">
                                        <Button block icon={<UploadOutlined />}>
                                          Click to upload
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                    <Form.Item className="" name="" label=":">
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm ms-3"
                                        onClick={() =>
                                          callImageAPI(logoFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </div>

                              <div className="upload">
                                <Form.Item
                                  className=""
                                  name="micrositeImages"
                                  label=" Images for your website"
                                >
                                  <Upload
                                    maxCount={3}
                                    listType="picture"
                                    accept=".jpg,.jpeg,.png"
                                  >
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Upload>
                                </Form.Item>
                                {!ismicrositeEmpty ? (
                                  <Form.Item className="" name="" label=":">
                                    {!ismicroSiteEmpty1 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mt-2 ms-2"
                                        onClick={() =>
                                          callImageAPI(microSiteFilename1)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {!ismicroSiteEmpty2 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mt-2 ms-2"
                                        onClick={() =>
                                          callImageAPI(microSiteFilename2)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {!ismicroSiteEmpty3 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mt-2 ms-2"
                                        onClick={() =>
                                          callImageAPI(microSiteFilename3)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Form.Item>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div>
                                <Form.Item
                                  className=""
                                  name="facebook"
                                  label="Facebook"
                                >
                                  <Input
                                    addonBefore={<FacebookOutlined />}
                                    placeholder="FaceBook ID"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className=""
                                  name="instagram"
                                  label="Instagram"
                                >
                                  <Input
                                    addonBefore={<InstagramOutlined />}
                                    placeholder="Instagram ID"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className=""
                                  name="linkedin"
                                  label="Linkedin"
                                >
                                  <Input
                                    addonBefore={<LinkedinOutlined />}
                                    placeholder="LinkedIn ID"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className=""
                                  name="twitter"
                                  label="Twitter"
                                >
                                  <Input
                                    addonBefore={<TwitterOutlined />}
                                    placeholder="Twitter ID"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  className=""
                                  name="otherlinks"
                                  label="Other links"
                                >
                                  <Input
                                    addonBefore={<LinkOutlined />}
                                    placeholder="Other Links(Optional)"
                                  />
                                </Form.Item>
                              </div>

                              <div className="row mb-3">
                                {/* *************************ngoCoordinator********************** */}
                                <legend className="para b themeCol-orange mx-4">
                                  NGO Coordinator Information:{" "}
                                </legend>

                                <div className="mt-4">
                                  <Form.Item
                                    name="coordinatorName"
                                    label="Name"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Coordinator name is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      size="medium"
                                      placeholder="NGO Coordinator Name"
                                    />
                                  </Form.Item>
                                </div>
                                <div>
                                  <Form.Item
                                    name="coordinatorPhone"
                                    label="Mobile"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Coordinator phone number is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      size="medium"
                                      placeholder="NGO Coordinator Mobile Numner"
                                    />
                                  </Form.Item>
                                </div>
                                <div>
                                  <Form.Item
                                    name="coordinatorEmail"
                                    label="Email"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Coordinator email is required",
                                      },
                                      {
                                        type: "email",
                                        message: "Enter valid email",
                                      },
                                    ]}
                                  >
                                    <Input
                                      size="medium"
                                      placeholder="NGO Coordinator Email"
                                    />
                                  </Form.Item>
                                </div>

                                {/* **********genenral info*********** */}
                                <legend className="para b themeCol-orange mx-4">
                                  General NGO Information:{" "}
                                </legend>
                                <div>
                                  <Form.Item
                                    className="para b themeCol-orange"
                                    name="about"
                                    label="About"
                                    //colon={false}
                                    rules={[
                                      {
                                        required: true,
                                        message: "About is required",
                                      },
                                    ]}
                                  >
                                    <Input.TextArea
                                      showCount
                                      maxLength={250}
                                      style={{ height: 150, resize: "none" }}
                                      placeholder="About the NGO (In 250 words)"
                                    />
                                  </Form.Item>
                                </div>
                                <div>
                                  <Form.Item
                                    name="history"
                                    label=" Brief History"
                                    //colon={false}
                                    rules={[
                                      {
                                        required: true,
                                        message: " Brief History is required",
                                      },
                                    ]}
                                  >
                                    <Input.TextArea
                                      showCount
                                      maxLength={250}
                                      style={{ height: 150, resize: "none" }}
                                      placeholder=" Brief History of the NGO (In 250 words)"
                                    />
                                  </Form.Item>
                                </div>
                                <div>
                                  <Form.Item name="vision" label="  Vision">
                                    <Input.TextArea
                                      showCount
                                      maxLength={150}
                                      style={{ height: 130, resize: "none" }}
                                      placeholder="  Vision of the NGO (In 150 words)"
                                    />
                                  </Form.Item>
                                </div>
                                <div>
                                  <Form.Item name="mission" label="  Mission">
                                    <Input.TextArea
                                      showCount
                                      maxLength={100}
                                      style={{ height: 130, resize: "none" }}
                                      placeholder="  Mission of the NGO (In 100 words)"
                                    />
                                  </Form.Item>
                                </div>
                                <div>
                                  <Form.Item
                                    className="para b themeCol-orange"
                                    name="geoFocus"
                                    label="Geography"
                                  >
                                    <Input
                                      showSearch
                                      placeholder="Geographic Focus of the activity"
                                      optionFilterProp="children"
                                      options={[]}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div className="col-md-6">
                        <fieldset>
                          <div className=" p-0">
                            {/* *****************Certificate***************** */}
                            <div className="container mt-1 mb-1 g-1 pt-2 text-start">
                              <legend className="para b themeCol-orange mx-2">
                                Copy of below Certificates to be uploaded:{" "}
                              </legend>
                              <div className="upload">
                                {iscertIncorpRocEmpty ? (
                                  <Form.Item
                                    className=""
                                    name="certIncorpRoc"
                                    label="Incorporation from ROC"
                                  >
                                    <Upload maxCount={1} listType="picture">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <>
                                    <Form.Item
                                      className=""
                                      name="certIncorpRoc"
                                      label="Incorporation from ROC"
                                    >
                                      <Upload maxCount={1} listType="picture">
                                        <Button block icon={<UploadOutlined />}>
                                          Click to upload
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                    <Form.Item className="" name="" label=":">
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm ms-3"
                                        onClick={() =>
                                          callImageAPI(certIncorpRocFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </div>
                              <div className="upload">
                                {ismoaAoaEmpty ? (
                                  <Form.Item
                                    className=""
                                    name="moaAoa"
                                    label="MOA / AOA"
                                  >
                                    <Upload maxCount={1} listType="picture">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <>
                                    <Form.Item
                                      className=""
                                      name="moaAoa"
                                      label="MOA / AOA"
                                    >
                                      <Upload maxCount={1} listType="picture">
                                        <Button block icon={<UploadOutlined />}>
                                          Click to upload
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                    <Form.Item className="" name="" label=" :">
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm ms-3"
                                        onClick={() =>
                                          callImageAPI(moaAoaFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </div>
                              <div className="upload">
                                {isregTrustDeedEmpty ? (
                                  <Form.Item
                                    className=""
                                    name="regTrustDeed"
                                    label="  Registered Trust Deed"
                                  >
                                    <Upload maxCount={1} listType="picture">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <>
                                    <Form.Item
                                      className=""
                                      name="regTrustDeed"
                                      label="  Registered Trust Deed"
                                    >
                                      <Upload maxCount={1} listType="picture">
                                        <Button block icon={<UploadOutlined />}>
                                          Click to upload
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                    <Form.Item className="" name="" label=":">
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm ms-3"
                                        onClick={() =>
                                          callImageAPI(regTrustDeedFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </div>
                              <div className="upload">
                                {ispancardEmpty ? (
                                  <Form.Item
                                    className=""
                                    name="pan"
                                    label="PAN"
                                  >
                                    <Upload maxCount={1} listType="picture">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <>
                                    <>
                                      <Form.Item
                                        className=""
                                        name="pan"
                                        label="PAN"
                                      >
                                        <Upload maxCount={1} listType="picture">
                                          <Button
                                            block
                                            icon={<UploadOutlined />}
                                          >
                                            Click to upload
                                          </Button>
                                        </Upload>
                                      </Form.Item>
                                      <Form.Item
                                        className=""
                                        name=""
                                        label=" :"
                                      >
                                        <Button
                                          type="button"
                                          className="btnnca btn-gold btn-sm ms-3"
                                          onClick={() =>
                                            callImageAPI(pancardFilename)
                                          }
                                        >
                                          Preview
                                        </Button>
                                      </Form.Item>
                                    </>
                                  </>
                                )}
                              </div>

                              <div className="upload">
                                {istanEmpty ? (
                                  <Form.Item
                                    className=""
                                    name="tan"
                                    label="TAN"
                                  >
                                    <Upload maxCount={1} listType="picture">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <>
                                    <Form.Item
                                      className=""
                                      name="tan"
                                      label="TAN"
                                    >
                                      <Upload maxCount={1} listType="picture">
                                        <Button block icon={<UploadOutlined />}>
                                          Click to upload
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                    <Form.Item className="" name="" label=":">
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm ms-3"
                                        onClick={() =>
                                          callImageAPI(tanFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </div>

                              <div className="upload">
                                {iscert12aEmpty ? (
                                  <Form.Item
                                    className=""
                                    name="cert12a"
                                    label=" 12A Cert."
                                  >
                                    <Upload maxCount={1} listType="picture">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <>
                                    <Form.Item
                                      className=""
                                      name="cert12a"
                                      label=" 12A Cert."
                                    >
                                      <Upload maxCount={1} listType="picture">
                                        <Button block icon={<UploadOutlined />}>
                                          Click to upload
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                    <Form.Item className="" label=":">
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm ms-3"
                                        onClick={() =>
                                          callImageAPI(cert12aFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </div>

                              <div className="upload">
                                {iscert80gEmpty ? (
                                  <Form.Item
                                    className=""
                                    name="cert80g"
                                    label=" 80G Cert."
                                  >
                                    <Upload maxCount={1} listType="picture">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <>
                                    <Form.Item
                                      className=""
                                      name="cert80g"
                                      label=" 80G Cert."
                                    >
                                      <Upload maxCount={1} listType="picture">
                                        <Button block icon={<UploadOutlined />}>
                                          Click to upload
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                    <Form.Item className="" name="" label=":">
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm ms-3"
                                        onClick={() =>
                                          callImageAPI(cert80gFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </div>

                              <div className="upload">
                                {iscsrForm1Empty ? (
                                  <Form.Item
                                    className=""
                                    name="csrForm1"
                                    label=" CSR Form 1."
                                  >
                                    <Upload maxCount={1} listType="picture">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <>
                                    <Form.Item
                                      className=""
                                      name="csrForm1"
                                      label=" CSR Form 1."
                                    >
                                      <Upload maxCount={1} listType="picture">
                                        <Button block icon={<UploadOutlined />}>
                                          Click to upload
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                    <Form.Item className="" name="" label=" :">
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm ms-3"
                                        onClick={() =>
                                          callImageAPI(csrForm1Filename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </div>
                              <div className="upload">
                                {iscertFcraEmpty ? (
                                  <Form.Item
                                    className=""
                                    name="certFcra"
                                    label=" FCRA Reg"
                                  >
                                    <Upload maxCount={1} listType="picture">
                                      <Button block icon={<UploadOutlined />}>
                                        Click to upload
                                      </Button>
                                    </Upload>
                                  </Form.Item>
                                ) : (
                                  <>
                                    <Form.Item
                                      className=""
                                      name="certFcra"
                                      label=" FCRA Reg"
                                    >
                                      <Upload maxCount={1} listType="picture">
                                        <Button block icon={<UploadOutlined />}>
                                          Click to upload
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                    <Form.Item className="" name="" label=":">
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm ms-3"
                                        onClick={() =>
                                          callImageAPI(certFcraFilename)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </div>

                              <legend className="para b themeCol-orange">
                                Only for available Years. Upload all 3 years
                                together.
                              </legend>

                              <div className="upload">
                                <Form.Item
                                  className=""
                                  name="itrForm"
                                  label="  ITR of last three AYs"
                                >
                                  <Upload maxCount={3} listType="picture">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Upload>
                                </Form.Item>
                                {!isitrFormEmpty ? (
                                  <Form.Item className="" name="" label=":">
                                    {!isitrFormEmpty1 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mt-2 ms-2"
                                        onClick={() =>
                                          callImageAPI(itrFormFilename1)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {!isitrFormEmpty2 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mt-2 ms-2"
                                        onClick={() =>
                                          callImageAPI(itrFormFilename2)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {!isitrFormEmpty3 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mt-2 ms-2"
                                        onClick={() =>
                                          callImageAPI(itrFormFilename3)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Form.Item>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="upload">
                                <Form.Item
                                  className=""
                                  name="form10B"
                                  label=" Form 10B of last three AYs"
                                >
                                  <Upload maxCount={3} listType="picture">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Upload>
                                </Form.Item>
                                {!isform10BEmpty ? (
                                  <Form.Item className="" name="" label=":">
                                    {!isform10BEmpty1 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mt-2 ms-2"
                                        onClick={() =>
                                          callImageAPI(form10BFilename1)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {!isform10BEmpty2 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mt-2 ms-2"
                                        onClick={() =>
                                          callImageAPI(form10BFilename2)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {!isform10BEmpty3 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mt-2 ms-2"
                                        onClick={() =>
                                          callImageAPI(form10BFilename3)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Form.Item>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="upload">
                                <Form.Item
                                  className=""
                                  name="fcraReturns"
                                  label=" FCRA Returns of last three FYs"
                                >
                                  <Upload maxCount={3} listType="picture">
                                    <Button block icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Upload>
                                </Form.Item>

                                {!isfcraEmpty ? (
                                  <Form.Item className="" name="" label=" :">
                                    {!isfcraReturnsEmpty1 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mt-2 ms-2"
                                        onClick={() =>
                                          callImageAPI(fcraReturnsFilename1)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {!isfcraReturnsEmpty2 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mt-2 ms-2"
                                        onClick={() =>
                                          callImageAPI(fcraReturnsFilename2)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {!isfcraReturnsEmpty3 ? (
                                      <Button
                                        type="button"
                                        className="btnnca btn-gold btn-sm mt-2 ms-2"
                                        onClick={() =>
                                          callImageAPI(fcraReturnsFilename3)
                                        }
                                      >
                                        Preview
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </Form.Item>
                                ) : (
                                  ""
                                )}
                              </div>

                              {/* ************Bank Details************ */}
                              <legend className="para b themeCol-orange">
                                Banking Information:{" "}
                              </legend>
                              <div className="mt-4">
                                <Form.Item
                                  name="bankName"
                                  label=" Bank Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: " Bank name is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Your Bank Name"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  name="accountName"
                                  label="Account Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: " Account  name is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Your Account Name"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  name="branchName"
                                  label="Branch Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: " Branch name is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Your Branch Name"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  name="accountNumber"
                                  label=" Account Number"
                                  rules={[
                                    {
                                      required: true,
                                      message: "  Account Number is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Your  Account Number"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  name="ifscCode"
                                  label="IFSC Code"
                                  rules={[
                                    {
                                      required: true,
                                      message: "IFSC Code is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Your IFSC Code"
                                  />
                                </Form.Item>
                              </div>
                              <div>
                                <Form.Item
                                  name="upiCode"
                                  label="UPI Code"
                                  rules={[
                                    {
                                      required: true,
                                      message: "UPI Code is required",
                                    },
                                  ]}
                                  hasFeedback
                                >
                                  <Input
                                    size="medium"
                                    placeholder="Your UPI Code"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <Button
                      htmlType="submit"
                      className="btnnca btn-pink btn-sm"
                      style={{ height: "30px" }}
                    >
                      <i className="fa fa-pencil-square-o"></i> Update NGO Profile
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <Modal
            open={isModalOpen}
            closeIcon={false}
            onOk={handleOk}
            centered={true}
            footer={[
              <div className="d-flex justify-content-center mt-3">
                <Button key="ok" type="primary" onClick={handleOk}>
                  OK
                </Button>
              </div>,
            ]}
          >
            {" "}
            <div className="d-flex justify-content-center">
              <img
                src={require("./../../assets/icons/check-icon.png")}
                alt="sucess-icon"
                className="sucessiconimg"
              />
            </div>
            <div className="d-flex justify-content-center mt-3">
              Profile updated successfully and sent for admin approval.
            </div>
            <div className="d-flex justify-content-center mt-2 b">
              {" "}
              Thank you!
            </div>
          </Modal>
        </>
      )}
      {error && (
        <>
          <div className="fontfamilynca">
            <div
              className="d-flex justify-content-center "
              style={{
                backgroundColor: "LightGray",
                height: "100vh",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="col-md-8 p-5 ml-5 mt-5">
                <div className="card ">
                  <div
                    className="card-header"
                    style={{
                      background:
                        "linear-gradient(270deg, #e09400 0%, #fdc24f 100%)",
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src={require("./../../assets/icons/error.png")}
                        alt="check-icon"
                        style={{height:80,width:80}}
                      />
                    </div>
                    <div className="text-center  text-white fw-bold">
                      DoAram - Update Profile
                    </div>
                  </div>

                  <div className="card-body ">
                    <p className="pt-2">
                      The Link can be used only once for updateing Your
                      Profile.Which is already done,Sorry for Inconvience.
                    </p>

                    <p className="pt-2  text-dark  d-flex justify-content-center">
                      For further clarification, reach us at &nbsp;
                      <a href="mailto:info@doaram.com">info@doaram.com</a>
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="text-center font12 text-success fw-bold pt-3">
                      <button
                        className="btn btn-success btn-sm"
                        type="button"
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        <i className="fa fa-arrow-left"></i>&nbsp;Back To Home
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Additionalinfo;
