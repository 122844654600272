import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import UploadService from '../../service/file-upload.service';
import { Table, Tag, Pagination, Modal, Form, Button,Input } from 'antd';

function ConceptNote() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [conceptnodemodel, setConceptnodemodel] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [recordData, setrecordData] = useState([]);
  const[refreshPage,setRefreshPage]=useState()
  const userCred = $.parseJSON(sessionStorage.getItem('da_userCred'));
  const userRoleAuthMapping = $.parseJSON(sessionStorage.getItem('da_authMapping'));


  const showModal = (record) => {
    setConceptnodemodel(true);
    setrecordData(record)
  };


  const handleCancel = () => {
    setConceptnodemodel(false);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const onsubmit = (value) => {
    console.log(recordData)
    const conceptnote={
            ngoId: userCred.associatedEntity,
            corpId: recordData.corpId,
            causeId: recordData.causeId,
            version: 3,
            type: "CONCEPT_NOTE",
            status: "CONCEPT_NOTE_SUBMITTED",
            comments: value.conceptnote,
            allowUpload: true,
            nextAction: "ngo",
            createdDate: "",
            createdBy: "corp_z zo_41",
            updatedDate: "",
            updatedBy: "corp_z zo_41"
        }
    const ConceptNotesendformData = new FormData();
    ConceptNotesendformData.append('userCred', JSON.stringify(userCred));
    ConceptNotesendformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNotesendformData.append('conceptNote', JSON.stringify(conceptnote)); 

    UploadService.create('ngo/conceptNote/update', ConceptNotesendformData)
      .then((response) => {
       console.log(response.data)
        setConceptnodemodel(false);
        setRefreshPage("")

      })
      .catch((error) => {
        console.error('Error fetching concept note data:', error);
      });
  }

  // Define your table columns
  const Donationcolumns = [
    {
      title: 'Cause',
      dataIndex: 'causeId',
      width: 60,
    },
    {
      title: 'Corporate',
      dataIndex: 'corpId',
      width: 90,
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      width: 90,
      render: (text) => text  // Ensure that no value is displayed if comments are missing
    },
    {
      title: 'Date',
      dataIndex: 'updatedDate',
      width: 90,
      render: (text) => {
        const datePart = text?.substr(0, 10); // Extracts the date part (YYYY-MM-DD)
        return datePart || 'N/A'; // Handle undefined or null dates
      },
    },
    {
      title: 'Concept Note Status',
      dataIndex: 'status',
      width: 90,
      render: (text) => {
        if (text === 'CONCEPT_NOTE_REQUESTED') {
          return <Tag color="#FFA500">Requested</Tag>;
        }
        if (text === 'CONCEPT_NOTE_SUBMITTED') {
          return <Tag color="pink">Submitted</Tag>;
        }
        if (text === 'CONCEPT_NOTE_REJECTED') {
          return <Tag color="red">Rejected</Tag>;
        }
        if (text === 'CONCEPT_NOTE_APPROVED') {
          return <Tag color="green">Approved</Tag>;
        }
        return text;
      },
    },
    {
      title: 'Upload',
      key: 'Upload',
      width: 90,
      render: (record) => {
        if (record.status === 'CONCEPT_NOTE_SUBMITTED' || record.status === 'CONCEPT_NOTE_REJECTED'|| record.status === 'CONCEPT_NOTE_APPROVED') {
          return (
            <span className="btn btn-red btn-sm para me-1">
            <i className="fas fa-ban text-white"></i>
          </span>
          );
        }
        
        return (
          <button className="btn btn-warning btn-sm ms-2" onClick={() => showModal(record)}>
            <i className="fa fa-upload text-white"></i> Upload
          </button>
        );
      },
    }
    
  ];

  
  useEffect(() => {
    const ConceptNoteformData = new FormData();
    ConceptNoteformData.append('userCred', JSON.stringify(userCred));
    ConceptNoteformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNoteformData.append('page', JSON.stringify(page - 1)); // Correct 0-indexed page
    ConceptNoteformData.append('size', JSON.stringify(size));


    UploadService.create('ngo/conceptNote/get', ConceptNoteformData)
      .then((response) => {
        const { content, totalElements } = response.data.data.conceptNote;
        setDataSource(content);
        setTotalElements(totalElements);
      })
      .catch((error) => {
        console.error('Error fetching concept note data:', error);
      });
  }, [page, size,refreshPage]); // Re-run effect when page or size changes

  return (
    <>
      <div className="col-md-12 row fontfamilynca p-2">
        <div className="col-sm-6">
          <h6 className="pt-2 tableheading">ConceptNote Response From NGO:</h6>
        </div>
        <section className="content">
          <div className="row">
            <div className="mt-3">
              <Table
                columns={Donationcolumns}
                dataSource={dataSource}
                style={{ padding: '5px' }}
                pagination={false}
                bordered
                scroll={{ x: 1000 }}
                className="custom-table"
              />
              <Pagination
                current={page}
                pageSize={size}
                onChange={handlePageChange}
                total={totalElements}
                showSizeChanger={false}
              />
            </div>
          </div>
        </section>
      </div>

      <Modal
        closeIcon={false}
        centered={true}
        open={conceptnodemodel}
        footer={[
            <div className="d-flex justify-content-center mt-3">
              <Button key="ok" type="primary" onClick={handleCancel}>
                Cancel
              </Button>
            </div>,
          ]}
      >
        <Form scrollToFirstError={true} name="validateOnly" onFinish={onsubmit}>
          <p>Please provide Concept Note( Minimum length 100):</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="conceptnote"
              rules={[
                {
                  required: true,
                  message: "Concept Note is required",
                },
              ]}
            >
              <Input.TextArea
                style={{ height: 100, resize: "none" }}
                placeholder="Concept Note"
                minLength={100}
                showCount
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <Button htmlType="submit" className="bg-yellowgra">
              Send +
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default ConceptNote;
