import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UploadService from "../../service/file-upload.service";
import $ from "jquery";
import {
  Table,
  Tag,
  Pagination,
} from "antd";

function NGO_Membershipstatus() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [totalElements, setTotalElements] = useState(0);
  const [totalpages, setTotalPages] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const handlePageChange = (page) => {
    setPage(page);
  };
  const generateColor = (userId) => {
    const colors = [
      "#f44336",
      "#2196f3",
      "#4caf50",
      "#ff9800",
      "#9c27b0",
      "#673ab7",
      "#ff5722",
    ];
    // Use a simple hash of userId to determine the index of the color
    const index = userId.charCodeAt(0) % colors.length;
    return colors[index];
  };
  const Donationcolumns = [
    {
      title: "Profile",
      dataIndex: "profile",
      width: 60,
      render: (text, record) => {
        const firstName = record.userId.charAt(0).toUpperCase();
        const color = generateColor(record.userId);
        return (
          <div className="col-md-12 row fontfamilynca " style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: "50%",
                backgroundColor: color,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 8,
              }}
            >
              <span style={{ color: "white" }}>{firstName}</span>
            </div>
            {/* You can also add an image here if available */}
          </div>
        );
      },
    },
    {
      title: "UserId",
      dataIndex: "userId",
      width: 90,
    },

    {
      title: "Fees Paid",
      dataIndex: "membershipFee",
      width: 90,
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "Requested",
      dataIndex: "membershipLevel",
      width: 90,
      render: (text) => {
        if (text === "NGO_APPLICANT") {
          return <Tag color="#e8c3cc">APPLICANT</Tag>;
        }
        if (text === "NGO_BRONZE") {
          return <Tag color="#cd7f32">BRONZE</Tag>;
        }
        if (text === "NGO_SILVER") {
          return <Tag color="#6a737b">SILVER</Tag>;
        }
        if (text === "NGO_GOLD") {
          return <Tag color="#d2af26">GOLD</Tag>;
        }
        if (text === "NGO_PLATINUM") {
          return <Tag color="#008eaa">PLATINUM</Tag>;
        }
        return text;
      },
    },
    {
      title: "Time",
      dataIndex: "orderTime",
      width: 90,
      render: (text) => {
        const datePart = text.substr(0, 10); // Extracts the date part (YYYY-MM-DD)
        return datePart;
      },
    },

    {
      title: "Status",
      dataIndex: "orderStatus",
      width: 90,
      render: (text) => {
        if (text === "Pending") {
          return <Tag color="#FFA500">Pending</Tag>;
        }
        if(text=="FULFILLED"){
          return <Tag color="green">Fulfilled</Tag>;

        }
      },
    },
  ];

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  const NGOMembershipstatusformData = new FormData();

  NGOMembershipstatusformData.append("userCred", JSON.stringify(userCred));

  NGOMembershipstatusformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );

  NGOMembershipstatusformData.append("page", JSON.stringify(page - 1));
  NGOMembershipstatusformData.append("size", JSON.stringify(size));
 
  useEffect(() => {
    UploadService.create(
      "ngo/membershipUpgrade/getAllOrders",
      NGOMembershipstatusformData
    ).then((response) => {
      setDataSource(response.data.data.orders.content);
      setTotalElements(response.data.data.orders.totalElements);
      setTotalPages(response.data.data.orders.totalPages);
    });
  }, [page]);
  return (
    <>
      <div className="col-md-12 row fontfamilynca p-2">
        <div className="col-sm-6">
          <h6 className="pt-2 tableheading">Current Membership:</h6>
        </div>
        <section className="content">
          <div className="row">
            <div className="mt-3">
              <Table
                columns={Donationcolumns}
                dataSource={dataSource}
                style={{ padding: "5px" }}
                onChange={handlePageChange}
                bordered
                scroll={{
                  x: 1000,
                }}
                pagination={false}
                className="custom-table"
              />
              <Pagination
                Current={page}
                pageSize={size}
                onChange={handlePageChange}
                total={totalElements}
                showSizeChanger={false}

              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default NGO_Membershipstatus;
