import React from "react";
import { Link } from "react-router-dom";
import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";
import Part from "./shared/becomepart/part";
import blog1img from "../doaram/assets/blog/blog1.jpg";
import blog2img from "../doaram/assets/blog/blog2.jpg";
import blog3img from "../doaram/assets/blog/blog3.jpg";
import blog4img from "../doaram/assets/blog/blog4.jpg";

function BlogDetails1() {
  return (
    <>
      <Header />
      {/* <!-- Facts Start --> */}
      <div className="container mt-5 webbody pt-5">
        <div
          className="text-center mx-auto wow fadeInUp pt-5  mb-5"
          data-wow-delay="0.1s"
        >
          <h1 className="mb-5 pt-5">
            {" "}
            A powerful medium to create public awareness is street play
          </h1>
        </div>
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="mb-5">
              <div className="post-slide ">
                <div className="post-img">
                  <img src={blog1img} alt="blog1img" />
                </div>
                <div className="post-content row">
                  <div className="col-md-9">
                    <h3 className="post-title">
                      <Link to="">
                        {" "}
                        A powerful medium to create public awareness is street
                        play
                      </Link>
                    </h3>
                    <p>
                      Street play is one of the oldest theatre forms where the
                      artists enact in public places with no specific target
                      audience. It is a genre of drama performed with music and
                      dance which is known for its simplicity and creative
                      execution. It serves as a metaphor that captures an image
                      of moral nature which every layman can relate to. It is
                      also known as 'nukad natak'.
                    </p>
                    <p>
                      A street play is known for its catchy dialogues,
                      attractive slogans and powerful dramatic elements like
                      tragedy, humor, mimicry etc. The youths mainly come
                      forward to play this genre. The primary goal of a street
                      play is taking a social message to a large group of
                      people. This helps to create a sense of awareness in the
                      society in which we live. Every street play has a specific
                      public social theme and the actor who performs should
                      posses the skill to exhibit and portrays the theme well.
                      The dialogues are colloquial and should be rendered
                      monotonously with a clear and loud voice. This kind of
                      recitation in high pitch will leave a huge impact in the
                      minds of people.
                    </p>
                    <p>
                      Generally the duration of the street play is very less. It
                      is not an easy task for any actors to perform in an
                      extensive group. Most of the time, the actors are
                      uniformly dressed in order to bring the uniformity in
                      behavior, mindset and outlook of the people. Lack of
                      proper theatre settings is an added advantage to get
                      connected with people in real life. This effective
                      technique will compel the spectators to stereotype with
                      the character played.
                    </p>
                    <p>
                      This genre of theatrical performance has evolved as an
                      influential medium in the 21<sup>st</sup> century to
                      transform the society. It unites the people from different
                      walks of life breaking the conventions of theatre.
                      Therefore Street plays have become popular genre in the
                      world of theatres, especially among the youths. Students
                      from school, NGO&rsquo;s and employees from corporate (as
                      a part of CSR activity) chose this vital tool to ignite
                      the minds of people to tackle with social and political
                      issues by taking their right to freedom of speech.
                    </p>
                    <p>
                      It is very surprising to learn that the spectators who
                      gets moved by the gravity of the issue donates resource or
                      help to raise funds for a cause for which the play is
                      indented to. Change in the society comes with the change
                      in the mindset of people. Street play can rightly achieve
                      the goal. Let&rsquo;s be the change to create a positive
                      and healthy place for our future gen to live.
                    </p>
                    <p>
                      {" "}
                      Share : &nbsp; &nbsp; &nbsp;
                      <div className="d-flex pt-2 ms-5">
                        <a className="btn btn-square btn-icon rounded-circle me-2">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a className="btn btn-square btn-icon rounded-circle me-2">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a className="btn btn-square btn-icon rounded-circle me-2">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </p>

                    <div className="row mt-3">
                      <div className="mb-3">
                        <label
                          for="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Comments
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          style={{ resize: "none" }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-end">
                      <button className="btn btn-secondary btn-sm  col-md-3">
                        Leave comment
                      </button>
                    </div>
                  </div>

                  <div
                    className="col-md-3 mb-5"
                    style={{ borderleft: "1px solid #ddd" }}
                  >
                    <div className="row mb-3">
                      <div className="categories">
                        <h6>
                          <u>Latest Blogs</u>
                        </h6>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <img
                                  src={blog2img}
                                  alt="..."
                                  style={{ width: "100px" }}
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                Moral education is a .
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <a>
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <img
                                    src={blog3img}
                                    alt="..."
                                    style={{ width: "100px" }}
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  A self-help group...
                                </div>
                              </div>
                            </a>
                          </li>
                          <li className="list-group-item">
                            <a>
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <img
                                    src={blog4img}
                                    alt="..."
                                    style={{ width: "100px" }}
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  Foot binding was...
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Facts End --> */}

      <Part />
      <Footer />
    </>
  );
}

export default BlogDetails1;
