
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import signupReducer from "./slices/signupSlice";
import axiosReducer from "./slices/axiosSlice";
import dataReducer from "./slices/corpdataSlice"; // Import the axios reducer
import micrositeProfileReducer from "./slices/micrositeProfileSlice";
import causeReducer from './slices/causeSlice';

const rootReducer = combineReducers({
  signup: signupReducer,
  axios: axiosReducer,
  data: dataReducer,
  micrositeProfile: micrositeProfileReducer,
  cause: causeReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  devTools:false,
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
