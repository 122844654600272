import { createSlice } from '@reduxjs/toolkit';

export const causeSlice = createSlice({
  name: 'cause',
  initialState: {
    data: {},
  },
  reducers: {
    setCauseData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setCauseData } = causeSlice.actions;

export const selectCauseData = (state) => state.cause.data;

export default causeSlice.reducer;
