import React, { useEffect, useState } from "react";
import { Table,Modal, Button, Tooltip, Pagination, Tag, Tabs } from "antd";
import CustomModal from "./ViewNgoModel";
import CauseModel from "./ViewCauseModel";
import DonatenowModel from "./Donatenow";
import ShowIntrest from "./IntrestDescription";
import Nodata from "../../assets/icons/Nocause.png";
import $ from "jquery";
import UploadService from "../../service/file-upload.service";

function Corp_Cause() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(7);
  const [totalElements, setTotalElements] = useState();
  const [dataSource, setDataSource] = useState();
  const [ngoid, setNgoid] = useState();
  const [ngopagemodel, setNgopagemodel] = useState(false);
  const [causepagemodel, setCausepagemodel] = useState(false);
  const [donatenowmodel, setDonatenowmodel] = useState(false);
  const [showInterestmodel, setShowInterestmodel] = useState(false);
  const [showInterestbtn, setShowInterestbtn] = useState();
  const [tablelength, setTablelength] = useState();
  const [reRendertable, setRerendertable] = useState();
  const [otherpage, setOtherPage] = useState(1);
  const [othersize, setOtherSize] = useState(7);
  const [othertotalElements, setOtherTotalElements] = useState();
  const [otherdataSource, setOtherDataSource] = useState();
  const [othertablelength, setOtherTablelength] = useState();
  const [visible, setVisible] = useState(false);
  const [causeId, setCauseId] = useState();
  const [entityId, setEntityId] = useState();
  const [donatengoId, setdonatengoId] = useState();
  const [donatecauseId, setdonatecauseId] = useState();

  const [activeTab, setActiveTab] = useState("1");

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleOtherPageChange = (otherpage) => {
    setOtherPage(otherpage);
  };
  const PrefCauseformData = new FormData();
  PrefCauseformData.append("userCred", JSON.stringify(userCred));
  PrefCauseformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  PrefCauseformData.append("prefFlag", "PREF_MEMBER_AND_CATEGORY");
  PrefCauseformData.append("page", page - 1);
  PrefCauseformData.append("size", size);

  const OtherCauseformData = new FormData();
  OtherCauseformData.append("userCred", JSON.stringify(userCred));
  OtherCauseformData.append(
    "userRoleAuthMapping",
    JSON.stringify(userRoleAuthMapping)
  );
  OtherCauseformData.append("prefFlag", "OTHER_MEMBER_AND_PREF_CATEGORY");
  OtherCauseformData.append("page", otherpage - 1);
  OtherCauseformData.append("size", othersize);

  useEffect(() => {
    UploadService.create("corp/get/cause/preference", PrefCauseformData).then(
      (response) => {
        setDataSource(response.data.data.causeInterestList);
        setTotalElements(response.data.data.totalElements);
        setTablelength(response.data.data.totalElements);
      }
    );
    UploadService.create("corp/get/cause/preference", OtherCauseformData).then(
      (response) => {
        setOtherDataSource(response.data.data.causeInterestList);
        setOtherTotalElements(response.data.data.totalElements);
        setOtherTablelength(response.data.data.totalElements);
      }
    );
  }, [page, otherpage, activeTab, reRendertable]);

  const truncateWithTooltip = (text, maxLength) => {
    if (text.length > maxLength) {
      return (
        <Tooltip title={text}>
          <span>{text.substr(0, maxLength)}...</span>
        </Tooltip>
      );
    }
    return text;
  };

  const columns = [
    {
      title: "NGO Name",
      dataIndex: ["cause", "name"],
    },
    {
      title: "Cause Category",
      dataIndex: ["cause", "category"],
      render: (text) => truncateWithTooltip(text, 30),
    },
    {
      title: "Location",

      render: (text, record) => (
        <span>{`${record.cause.city}, ${record.cause.state}`}</span>
      ),
    },

    {
      title: "EndDate",
      dataIndex: ["cause", "end_date"],
      render: (text) => {
        const datePart = text.substr(0, 10);
        return datePart;
      },
    },

    {
      title: "Membership",
      dataIndex: ["cause", "membership"],
      render: (text) => {
        if (text === "NGO_APPLICANT") {
          return <Tag color="#e8c3cc">APPLICANT</Tag>;
        }
        if (text === "NGO_BRONZE") {
          return <Tag color="#cd7f32">BRONZE</Tag>;
        }
        if (text === "NGO_SILVER") {
          return <Tag color="#6a737b">SILVER</Tag>;
        }
        if (text === "NGO_GOLD") {
          return <Tag color="#d2af26">GOLD</Tag>;
        }
        if (text === "NGO_PLATINUM") {
          return <Tag color="#008eaa">PLATINUM</Tag>;
        }
        return text;
      },
    },
    {
      title: "Action",
      key: "operation",
      render: (record) => (
        <div className="d-flex">
          <Tooltip title="View Profile">
            <a onClick={() => viewProfile(record)}>
              <span className="btn  btn-info btn-sm para ">
                <i className="fas fa fa-globe text-white"></i>
              </span>
            </a>
          </Tooltip>
          <Tooltip title="View Cause">
            <a onClick={() => viewCause(record)}>
              <span className="btn btn-warning btn-sm para ms-1  ">
                <i className="fas fa-eye text-white"></i>
              </span>
            </a>
          </Tooltip>

          <Tooltip title="Donate Now">
            <a onClick={() => donatenow(record)}>
              <span className="btn btn-violet btn-sm para ms-1  ">
                <i className="fas fa-hand-holding-usd text-white"></i>
              </span>
            </a>
          </Tooltip>


          {record.interest === null ? (
            <Tooltip title="Show interest">
              <a onClick={() => showinterest(record)}>
              <span className="btn btn-warning btn-sm para ms-1   ">
                <i className="fas fa-heart text-white"></i>
              </span>
            </a>
            </Tooltip>
          ) : (
            <Tooltip title="Already Shown interest">
              <Tag color="green" className="ms-1 p-1">
                Shown Interest
              </Tag>
            </Tooltip>
          )}

            <Tooltip title="Request ConceptNote">
            <a onClick={() => requestConceptNote(record)}>
              <span className="btn btn-violet btn-sm para ms-1  ">
                <i className="fas fa-file-alt text-white"></i>
              </span>
            </a>
          </Tooltip>
        </div>
      ),
    },
  ];

  const viewCause = (record) => {
    setCausepagemodel(true);
    setCauseId(record.cause.causeId);
    if (record.interest === null) {
      setShowInterestbtn(true);
    } else {
      setShowInterestbtn(false);
    }
  };
  const viewProfile = (record) => {
    setNgopagemodel(true);
    setNgoid(record.cause.entityId);
  };
  const donatenow = (record) => {
    setDonatenowmodel(true);
    setdonatengoId(record.cause.entityId);
    setdonatecauseId(record.cause.causeId);
  };
  const showinterest = (record) => {
    setShowInterestmodel(true);
    setCauseId(record.cause.causeId);
    setEntityId(record.cause.entityId);
  };

  const requestConceptNote=(record)=>{
    const conceptNote={
             ngoId: record.cause.entityId,
             corpId: userCred.associatedEntity,
             causeId: record.cause.causeId,
             version: 1,
             type: "CONCEPT_NOTE",
             status: "CONCEPT_NOTE_REQUESTED",
             comments: "give us a concept note",
             allowUpload: true,
             nextAction: "ngo",
             createdDate: "",
             createdBy: "",
             updatedDate: "",
             updatedBy: ""}
             console.log(conceptNote)
    const CorpCauseformData = new FormData();
    CorpCauseformData.append("userCred", JSON.stringify(userCred));
    CorpCauseformData.append( "userRoleAuthMapping",JSON.stringify(userRoleAuthMapping));
    CorpCauseformData.append("conceptNote",JSON.stringify(conceptNote) );

    UploadService.create("corp/conceptNote/create",CorpCauseformData)
    .then((response) => {
      console.log(response.data)
      setVisible(true);
    })
    .catch((error) => {
      console.error('Error fetching concept note data:', error);
    
      if (error.response && error.response.status === 409) {
        Modal.confirm({
          title: 'Requested Concept Note',
          content: (
            <p>You have already requested a Concept Note for this cause. Please check the Concept Note tab.</p>
          ),
          okText: 'Close',
          cancelButtonProps: { style: { display: 'none' } }, // Hides the Cancel button
        });
      } else {
        Modal.error({
          title: 'Error',
          content: (
            <p>
              Something went wrong while fetching the Concept Note data. Please try again later.
            </p>
          ),
        });
      }
    });
    
  
  }

  return (
    <>
      <>
        <div className="col-md-12 row fontfamilynca  pt-2 px-4">
          <div className="col-sm-6">
            <h6 className="tableheading">Approved Cause:</h6>
          </div>
          <Tabs
            defaultActiveKey={activeTab}
            centered
            type="card"
            large
            items={[
              {
                label: " Preferred Causes",
                key: "1",
                children: (
                  <section className="content">
                    {tablelength > 0 && (
                      <div className="row">
                        <div className="mt-2">
                          <Table
                            columns={columns}
                            dataSource={dataSource}
                            onChange={handlePageChange}
                            bordered
                            pagination={false}
                            className="custom-table"
                            scroll={{
                              x: 1100,
                            }}
                          />
                          <Pagination
                            Current={page}
                            total={totalElements}
                            pageSize={size}
                            onChange={handlePageChange}
                            showSizeChanger={false}
                          />
                        </div>
                      </div>
                    )}
                    {tablelength == 0 && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "80vh" }}
                      >
                        <img
                          src={Nodata}
                          alt="Nodata"
                          className="alignimagecenter"
                        />
                      </div>
                    )}
                  </section>
                ),
              },
              {
                label: " Other Causes",
                key: "2",
                children: (
                  <section className="content">
                    {othertablelength > 0 && (
                      <div className="row">
                        <div className="mt-2">
                          <Table
                            columns={columns}
                            dataSource={otherdataSource}
                            onChange={handlePageChange}
                            bordered
                            pagination={false}
                            className="custom-table"
                            scroll={{
                              x: 1100,
                            }}
                          />
                          <Pagination
                            Current={otherpage}
                            total={othertotalElements}
                            pageSize={othersize}
                            onChange={handleOtherPageChange}
                            showSizeChanger={false}
                          />
                        </div>
                      </div>
                    )}
                    {othertablelength == 0 && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "80vh" }}
                      >
                        <img
                          src={Nodata}
                          alt="Nodata"
                          className="alignimagecenter"
                        />
                      </div>
                    )}{" "}
                  </section>
                ),
              },
            ]}
          />
        </div>
      </>

      <CustomModal
        visible={ngopagemodel}
        onClose={() => setNgopagemodel(false)}
        data={ngoid}
      />

      <CauseModel
        visible={causepagemodel}
        onClose={() => setCausepagemodel(false)}
        data={causeId}
        interest={showInterestbtn}
      />
      <DonatenowModel
        visible={donatenowmodel}
        onClose={() => setDonatenowmodel(false)}
        donatecauseId={donatecauseId}
        donatengoId={donatengoId}
      />
      <ShowIntrest
        visible={showInterestmodel}
        onClose={() => setShowInterestmodel(false)}
        causeId={causeId}
        entityId={entityId}
      />
      <Modal
        visible={visible}
        footer={null}
        closeIcon={false}
        centered={true}
        okText="OK"
      >
        <p className="d-flex  justify-content-center">Concept Note is Requested Successfully.</p>
        <div className="d-flex  justify-content-center">
            <Button  className="btn-green mt-3" onClick={()=>{ setVisible(false)}}>
              ok
            </Button>
          </div>
      </Modal>
    </>
  );
}

export default Corp_Cause;
