import React from "react";
import { Link } from "react-router-dom";
import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";
import Part from "./shared/becomepart/part";
import blog1img from "../doaram/assets/blog/blog1.jpg";
import blog2img from "../doaram/assets/blog/blog2.jpg";
import blog3img from "../doaram/assets/blog/blog3.jpg";
import blog4img from "../doaram/assets/blog/blog4.jpg";

function BlogDetails4() {
  return (
    <>
      <Header />
      {/* <!-- Facts Start --> */}
      <div className="container mt-5 webbody pt-5">
        <div
          className="text-center mx-auto wow fadeInUp pt-5  mb-5"
          data-wow-delay="0.1s"
        >
          <h1 className="mb-5 pt-5">
            {" "}
            Foot binding was a symbol of status, modesty and moral virtue
          </h1>
        </div>
        <div className="col-md-12 mt-5">
          <div className="row">
            <div className="mb-5">
              <div className="post-slide ">
                <div className="post-img">
                  <img src={blog4img} alt="blog4img" />
                </div>
                <div className="post-content row">
                  <div className="col-md-9">
                    <h3 className="post-title">
                      <Link to="">
                        Foot binding was a symbol of status, modesty and moral
                        virtue
                      </Link>
                    </h3>
                    <p>
                      A 12<sup>th</sup> Chinese proverb evident to foot binding
                      says 'if you care for your children, do not worry if your
                      son suffers for his studies or your daughter for her feet'
                    </p>
                    <p>
                      Foot binding was one of the unique, ancient practices
                      amongst the upper className women in China. It started as a
                      fashionable impulse during the Tang dynasty where one of
                      the emperor&rsquo;s concubines named Yao Niang performed
                      dance in the court. She entranced the emperor Li Yu
                      dancing on toes in a six-foot golden lotus wrapped in
                      ribbons and precious stones. This practice not only
                      altered the shape of the toes but in addition it produced
                      a sort of confidence in gait especially in the back and
                      thigh muscle. In fact, foot binding inculcates erotic
                      overtones right from the beginning. Gradually, women with
                      money and time from the court took up foot binding making
                      it as a status symbol of the elite crowd.
                    </p>
                    <p>
                      The young girls of Tang dynasty had their feet bound
                      typically from the age five to eight. Though considered
                      attractive its effects are painful and permanent
                      throughout their lives. Usually, an auspicious day is
                      chosen and prayers are offered to the Tiny-foot goddess
                      who is believed to protect the girls. The older member of
                      the family or a professional foot-binder binds the feet
                      tightly with long strips of cloth, which restricts further
                      growth of the feet and gave the pronounced arc. The aim of
                      this process is to achieve a feet no longer than 3-4
                      inches, which is also called a &ldquo;golden lotus&rdquo;.
                      The smaller the feet, the more attractive it is. However,
                      their mobility is greatly reduced. Smaller feet especially
                      required dainty shoes which are made of silk or cotton
                      beautifully embroidered
                    </p>
                    <p>
                      Inspired from the Tang dynasty foot binding was widespread
                      in the Song dynasty among the aristocrats. Subsequently,
                      it was practiced by all classes of woman. The lower className
                      parents saw this practice as an opportunity to raise
                      prospects of their own children. Eventually, this became
                      an important trait in arranging marriage for their sons.
                      Girls with the Golden lotus are said to be fortuitous and
                      a reliable bride. It is considered respectful to have a
                      four feet foot, silver lotus however, a foot which is five
                      feet or more is considered to be iron lotuses and are
                      generally dismissed. Henceforth, the marriage prospects of
                      such girls are indeed dim. Hence Foot binding not only
                      symbolized elegance but modesty and moral values.
                    </p>
                    <p>
                      Emperors in the following dynasty, Chun chi and
                      K&rsquo;sang Hsi made attempts to ban this practice and
                      never acknowledged foot bounding in their court. It
                      remained unimpressed to the scholars of confucious as it
                      was associated with the woman from the
                      &ldquo;entertainment industry&rdquo; and a sole purpose of
                      making woman more attractive. By the 18<sup>th</sup>{" "}
                      century CE, some popular movements have put an end to this
                      practice however still, despite all pain and consequences
                      foot binding was practiced in china into the early 21
                      <sup>st</sup> century. The last lotus shoe that the
                      company produced was in 1999.
                    </p>
                    <p>
                      We still have few survivors with the lotus feet. Having
                      unbound their feet for 50-60 long years it is naturally
                      spread. But they have attained the beauty of the society.
                    </p>
                    <p>
                      {" "}
                      Share : &nbsp; &nbsp; &nbsp;
                      <div className="d-flex pt-2 ms-5">
                        <a className="btn btn-square btn-icon rounded-circle me-2">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a className="btn btn-square btn-icon rounded-circle me-2">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a className="btn btn-square btn-icon rounded-circle me-2">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </p>

                    <div className="row mt-3">
                      <div className="mb-3">
                        <label
                          for="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Comments
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          style={{ resize: "none" }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-end">
                      <button className="btn btn-secondary btn-sm  col-md-3">
                        Leave comment
                      </button>
                    </div>
                  </div>

                  <div
                    className="col-md-3 mb-5"
                    style={{ borderleft: "1px solid #ddd" }}
                  >
                    <div className="row mb-3">
                      <div className="categories">
                        <h6>
                          <u>Latest Blogs</u>
                        </h6>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <div className="d-flex">
                              <div className="flex-shrink-0">
                                <img
                                  src={blog1img}
                                  alt="..."
                                  style={{ width: "100px" }}
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                Street play is one of...
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <a>
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <img
                                    src={blog2img}
                                    alt="..."
                                    style={{ width: "100px" }}
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  Moral education is a...
                                </div>
                              </div>
                            </a>
                          </li>
                          <li className="list-group-item">
                            <a>
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <img
                                    src={blog3img}
                                    alt="..."
                                    style={{ width: "100px" }}
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  A self-help group is
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Facts End --> */}

      <Part />
      <Footer />
    </>
  );
}

export default BlogDetails4;
