import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import UploadService from '../../service/file-upload.service';
import { Table, Tag, Pagination, Modal, Form, Button,Input } from 'antd';
function Corp_ConcepNote() {

 const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [totalElements, setTotalElements] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [acceptmodel, setAcceptmodel] = useState(false);
  const [rejectmodel, setRejectmodel] = useState(false);
  const[refreshPage,setRefreshPage]=useState()

  const userCred = $.parseJSON(sessionStorage.getItem('da_userCred'));
  const userRoleAuthMapping = $.parseJSON(sessionStorage.getItem('da_authMapping'));

   useEffect(() => {
    const ConceptNoteformData = new FormData();
    ConceptNoteformData.append('userCred', JSON.stringify(userCred));
    ConceptNoteformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNoteformData.append('page', JSON.stringify(page - 1));
    ConceptNoteformData.append('size', JSON.stringify(size));

    UploadService.create('corp/conceptNote/get', ConceptNoteformData)
      .then((response) => {
        const { content, totalElements } = response.data.data.conceptNote;
        setDataSource(content);
        setTotalElements(totalElements);
      })
      .catch((error) => {
        console.error('Error fetching concept note data:', error);
      });
  }, [page, size,refreshPage]); 

  const Donationcolumns = [
    {
      title: 'Cause',
      dataIndex: 'causeId',
      width: 60,
    },
    {
      title: 'Corporate',
      dataIndex: 'corpId',
      width: 90,
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      width: 90,
      render: (text) =>  text
    },
    {
      title: 'Date',
      dataIndex: 'updatedDate',
      width: 90,
      render: (text) => {
        const datePart = text?.substr(0, 10); 
        return datePart || 'N/A';
      },
    },
    {
      title: 'Concept Note Status',
      dataIndex: 'status',
      width: 90,
      render: (text) => {
        if (text === 'CONCEPT_NOTE_REQUESTED') {
          return <Tag color="#FFA500">Requested</Tag>;
        }
        if (text === 'CONCEPT_NOTE_SUBMITTED') {
          return <Tag color="pink">Submitted</Tag>;
        }
        if (text === 'CONCEPT_NOTE_REJECTED') {
          return <Tag color="red">Rejected</Tag>;
        }
        if (text === 'CONCEPT_NOTE_APPROVED') {
          return <Tag color="green">Approved</Tag>;
        }
        return text;
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (record) => {
        if (record.status === 'CONCEPT_NOTE_REQUESTED' ||record.status === 'CONCEPT_NOTE_REJECTED'||record.status === 'CONCEPT_NOTE_APPROVED') {
          // Render the special button if status is 'CONCEPT_NOTE_REQUESTED'
          return (
            <>
            <span className="btn btn-green btn-sm para me-1">
              <i className="fas fa-ban text-white"></i>
            </span>
             <span className="btn btn-red btn-sm para me-1">
             <i className="fas fa-ban text-white"></i>
           </span>
           </>
          );
        }
        return (
          <div>
            <button className="btn btn-green btn-sm ms-2" onClick={() => acceptConteptnote(record)}>
              <i className="fa fa-check text-white"></i> Accept
            </button>
            <button className="btn btn-red btn-sm ms-2" onClick={() => rejectConteptnote(record)}>
              <i className="fa fa-ban text-white"></i> Reject
            </button>
          </div>
        );
      },
    }
    
      
  ];

  const acceptConteptnote=(record)=>{
    const ConceptNoteAcceptformData = new FormData();
    ConceptNoteAcceptformData.append('userCred', JSON.stringify(userCred));
    ConceptNoteAcceptformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNoteAcceptformData.append('conceptNote', JSON.stringify(record));
    UploadService.create('corp/conceptNote/approve', ConceptNoteAcceptformData)
    .then((response) => {
      console.log(response.data.data)
      setAcceptmodel(true)
      setRefreshPage("")
    })
    .catch((error) => {
      console.error('Error fetching concept note data:', error);

    });

  }
  const rejectConteptnote=(record)=>{
    const ConceptNoteRejectformData = new FormData();
    ConceptNoteRejectformData.append('userCred', JSON.stringify(userCred));
    ConceptNoteRejectformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNoteRejectformData.append('conceptNote', JSON.stringify(record));
    UploadService.create('corp/conceptNote/reject', ConceptNoteRejectformData)
    .then((response) => {
      setRejectmodel(true)
      setRefreshPage("")
    })
    .catch((error) => {
      console.error('Error fetching concept note data:', error);
    });

  }
  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className="col-md-12 row fontfamilynca p-2">
        <div className="col-sm-6">
          <h6 className="pt-2 tableheading">Corporate Requested ConcepNote:</h6>
        </div>
        <section className="content">
          <div className="row">
            <div className="mt-3">
              <Table
                columns={Donationcolumns}
                dataSource={dataSource}
                style={{ padding: '5px' }}
                pagination={false}
                bordered
                scroll={{ x: 1000 }}
                className="custom-table"
              />
              <Pagination
                current={page}
                pageSize={size}
                onChange={handlePageChange}
                total={totalElements}
                showSizeChanger={false}
              />
            </div>
          </div>
        </section>
        <Modal
        visible={acceptmodel}
        footer={null}
        closeIcon={false}
        centered={true}
        okText="OK"
      >
        <p className="d-flex  justify-content-center">Concept Note Accepted Successfully.</p>
        <div className="d-flex  justify-content-center">
            <Button  className="btn-green mt-3" onClick={()=>{setAcceptmodel(false)}}>
              ok
            </Button>
          </div>
      </Modal>
      <Modal
        visible={rejectmodel}
        footer={null}
        closeIcon={false}
        centered={true}
        okText="OK"
      >
        <p className="d-flex  justify-content-center">Concept Note Rejected.</p>
        <div className="d-flex  justify-content-center">
            <Button  className="btn-red mt-3" onClick={()=>{setRejectmodel(false)}}>
              ok
            </Button>
          </div>
      </Modal>
      </div> 
       )
}

export default Corp_ConcepNote