import React, { useEffect, useState } from "react";
import UploadService from "../../service/file-upload.service";
import Nodata from "../../assets/icons/Nocause.png";
import { Table, message, Pagination, Tag } from "antd";
import $ from "jquery";
function CorpDonation() {
  const [page, setPage] = useState(1);
  const [size] = useState(8);
  const [totalElements, setTotalElements] = useState();
  const [dataSource, setDataSource] = useState([]);

  const handlePageChange = (page) => {
    setPage(page);
  };
  let tablelength = dataSource.length;
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  useEffect(() => {
    const CorpDonationformData = new FormData();
    CorpDonationformData.append("userCred", JSON.stringify(userCred));
    CorpDonationformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    CorpDonationformData.append("causeId", "");
    CorpDonationformData.append("status", "");
    CorpDonationformData.append("entityId", userCred.associatedEntity);
    CorpDonationformData.append("page", JSON.stringify(page - 1));
    CorpDonationformData.append("size", JSON.stringify(size));
    UploadService.create(
      "/corp/payments/donations/get",
      CorpDonationformData
    ).then((response) => {
      setDataSource(response.data.data.corpDonationUserDetailsPage.content);
      setTotalElements(
        response.data.data.corpDonationUserDetailsPage.totalElements
      );
    });
  }, [page]);

  const columns = [
    // { title: "Email", width: 90, dataIndex: "email" },
    { title: "NGOID", width: 90, dataIndex: "ngoId" },
    { title: "CAUSEID", width: 90, dataIndex: "causeId" },
    { title: "ORDERID", width: 90, dataIndex: "orderId" },
    {
      title: "DONATED",
      width: 90,
      dataIndex: "donatedAmount",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "CCAVENUE",
      width: 90,
      dataIndex: "gatewayCreditedAmount",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "DOARAM",
      width: 90,
      dataIndex: "doaramPlatformFees",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "NGO",
      width: 90,
      dataIndex: "finalAmountAfterFees",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "PAYMENTMODE",
      width: 90,
      dataIndex: "paymentMode",
    },
    {
      title: " Donation Status",
      dataIndex: "status",
      width: 90,
      render: (text) => {
        if (text === "READY_FOR_FULFILLMENT") {
          return <Tag color="#C9CC3F">Waiting</Tag>;
        }
        if (text === "Pending") {
          return <Tag color="#F89880">Pending</Tag>;
        }
        if (text === "Failure") {
          return <Tag color="#c04000">Failure</Tag>;
        }
        if (text === "FULFILLED") {
          return <Tag color="#198754">Fulfilled</Tag>;
        }
        if (text === "Cancel") {
          return <Tag color="#FF0000">Canceled</Tag>;
        }
      },
    },
    {
      title: "80g Certificate",
      width: 90,
      dataIndex: "cert80g",
      render: (text, record) => {
        if (text === "PENDING" || text === "") {
          return <Tag color="#C9CC3F">No File</Tag>;
        } else {
          return (
            <span
              className="btn btn-info btn-sm para ms-1 "
              onClick={() => Corpdownload80g(record)}
            >
              <i className="fas fa-download text-white"></i>
            </span>
          );
        }
      },
    },
  ];
  const Corpdownload80g = (record) => {
    const Download80gformData = new FormData();
    Download80gformData.append("userCred", JSON.stringify(userCred));
    Download80gformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    Download80gformData.append(
      "corpDonationUserDetails",
      JSON.stringify(record)
    );
    UploadService.filedownload("corp/donation/cert80g/get", Download80gformData)
      .then((response) => {
        downloadBlob(response.data, record.cert80g);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  function downloadBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  return (
    <>
      <div className="col-md-12 row fontfamilynca   pt-2 px-4">
        <div className="col-sm-6">
          <h5 className="tableheading ">MY CAUSE DONATION:</h5>
        </div>
        <section className="content">
          {tablelength > 0 && (
            <div className="row">
              <div className="mt-3">
                <Table
                  columns={columns}
                  style={{ padding: "5px" }}
                  dataSource={dataSource}
                  onChange={handlePageChange}
                  bordered
                  scroll={{
                    x: 1000,
                  }}
                  className="custom-table"
                  pagination={false}
                />
                <Pagination
                  Current={page}
                  total={totalElements}
                  pageSize={size}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                />
              </div>
            </div>
          )}
          {tablelength == 0 && (
            <div
              className="d-flex justify-content-center"
              style={{ height: "80vh" }}
            >
              <img src={Nodata} alt="Nodata" className="alignimagecenter" />
            </div>
          )}
        </section>
      </div>
    </>
  );
}

export default CorpDonation;
