import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UploadService from "../../service/file-upload.service";
import Nodata from "../../assets/icons/Nodonation.png";

import $ from "jquery";
import { Table, Modal, Input, Button, Form, Tabs, Pagination, Tag } from "antd";

function Admin_CorpDonation() {
  const [fullfillmentpage, setFullfillmentPage] = useState(1);
  const [fullfillmentsize] = useState(5);
  const [fullfillmentdataSource, setFullfillmentDataSource] = useState([]);
  const [fullfillmenttotalElements, setFullfillmentTotalElements] = useState(0);
  const [reRendertable, setRerendertable] = useState();

  const [corpfullfillmentpage, setCorpFullfillmentPage] = useState(1);
  const [corpfullfillmentsize] = useState(5);
  const [corpfullfillmentdataSource, setCorpFullfillmentDataSource] = useState(
    []
  );
  const [corpfullfillmenttotalElements, setCorpFullfillmentTotalElements] =
    useState(0);

  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  let tablelength = fullfillmentdataSource.length;
  let corptablelength = corpfullfillmentdataSource.length;


  const fullfillmentPageChange = (fullfillmentpage) => {
    setFullfillmentPage(fullfillmentpage);
  };

  const corpfullfillmentPageChange = (corpfullfillmentpage) => {
    setCorpFullfillmentPage(corpfullfillmentpage);
  };
  useEffect(() => {
    const FullfillformData = new FormData();

    FullfillformData.append("userCred", JSON.stringify(userCred));
    FullfillformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    FullfillformData.append("page", fullfillmentpage - 1);
    FullfillformData.append("size", fullfillmentsize);
    UploadService.create(
      "admin/donations/orders/fulfill/get",
      FullfillformData
    ).then((response) => {
      console.log("ind",response.data.data.donationUserDetails.content)
      setFullfillmentDataSource(response.data.data.donationUserDetails.content);
      setFullfillmentTotalElements(
        response.data.data.donationUserDetails.totalElements
      );
    });

    const CorpFullfillformData = new FormData();
    CorpFullfillformData.append("userCred", JSON.stringify(userCred));
    CorpFullfillformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    CorpFullfillformData.append("page", corpfullfillmentpage - 1);
    CorpFullfillformData.append("size", corpfullfillmentsize);
    UploadService.create(
      "/admin/corp/donations/orders/fulfill/get",
      CorpFullfillformData
    ).then((response) => {
      console.log("corp",response.data.data.corpDonationUserDetails.content)
      setCorpFullfillmentDataSource(
        response.data.data.corpDonationUserDetails.content
      );
      setCorpFullfillmentTotalElements(
        response.data.data.corpDonationUserDetails.totalElements
      );
    });
  }, [fullfillmentpage, corpfullfillmentpage, reRendertable]);

  const onsubmit = (values) => {
    const fullfillcauseformData = new FormData();

    fullfillcauseformData.append("userCred", JSON.stringify(userCred));
    fullfillcauseformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    fullfillcauseformData.append("orderIdStringList", values.OrderId);

    UploadService.create(
      "admin/donations/orders/fulfill",
      fullfillcauseformData
    ).then((response) => {
      if (response.data.message.messageType == "Success") {
        setRerendertable(values);
        Modal.info({
          content: (
            <div>
              <p>Cause FullFillment Sucessfull.</p>
            </div>
          ),
        });
      }
    });
  };

  const onCorpsubmit = (values) => {
    const fullfillcorpcauseformData = new FormData();

    fullfillcorpcauseformData.append("userCred", JSON.stringify(userCred));
    fullfillcorpcauseformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    fullfillcorpcauseformData.append("orderIdStringList", values.OrderId);

    UploadService.create(
      "admin/corp/donations/orders/fulfill",
      fullfillcorpcauseformData
    ).then((response) => {
      if (response.data.message.messageType == "Success") {
        setRerendertable(values);
        Modal.info({
          content: (
            <div>
              <p>Cause FullFillment Sucessfull.</p>
            </div>
          ),
        });
      }
    });
  };

  const fullfillmentcolumns = [
    {
      title: "User Name",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "NGO Details",
      dataIndex: "ngoId",
      align: "center",
    },
    {
      title: "Cause Details",
      dataIndex: "causeId",
      align: "center",
    },
    {
      title: "Order Details",
      dataIndex: "orderId",
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "donatedAmount",
      align: "center",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      align: "center",
    },
    {
      title: "FullFill Cause",
      dataIndex: "Fullfill",
      align: "center",
      render: (record) => (
        <div>
          <Form autoComplete="off" onFinish={onsubmit} className="d-flex">
            <Form.Item
              name="OrderId"
              rules={[
                {
                  required: true,
                  message: "OrderId is required to Fulfill",
                },
              ]}
            >
              <Input placeholder="Order Id" style={{ minWidth: "100px" }} />
            </Form.Item>
            <Button
              className="btnnca btn-pink btn-sm mx-2"
              htmlType="submit"
              style={{ marginTop: "1px" }}
            >
              Submit
            </Button>
          </Form>
        </div>
      ),
    },
  ];

  const corpfullfillmentcolumns = [
    {
      title: "User Name",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "NGO Details",
      dataIndex: "ngoId",
      align: "center",
    },
    {
      title: "Cause Details",
      dataIndex: "causeId",
      align: "center",
    },
    {
      title: "Order Details",
      dataIndex: "orderId",
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "donatedAmount",
      align: "center",
      render: (text) => <span>&#x20B9; {text}</span>,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      align: "center",
    },
    {
      title: "FullFill Cause",
      dataIndex: "Fullfill",
      align: "center",
      render: (record) => (
        <div>
          <Form autoComplete="off" onFinish={onCorpsubmit} className="d-flex">
            <Form.Item
              name="OrderId"
              rules={[
                {
                  required: true,
                  message: "OrderId is required to Fulfill",
                },
              ]}
            >
              <Input placeholder="Order Id" style={{ minWidth: "100px" }} />
            </Form.Item>
            <Button
              className="btnnca btn-pink btn-sm mx-2"
              htmlType="submit"
              style={{ marginTop: "1px" }}
            >
              Submit
            </Button>
          </Form>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="col-md-12 row fontfamilynca    pt-2 px-4">
        <div className="col-sm-6">
          <h6 className="tableheading">Cause Ready for Fullfillment:</h6>
        </div>
        <Tabs
          defaultActiveKey="1"
          centered
          bordered={false}
          type="card"
          large
          items={[
            {
              label: "Individual Fullfillment",
              key: "1",
              children: (
                <section className="content ">
                  {tablelength > 0 && (
                    <div className="row">
                      <div className="mt-3  ">
                        <Table
                          columns={fullfillmentcolumns}
                          dataSource={fullfillmentdataSource}
                          className="custom-table"
                          style={{ padding: "5px" }}
                          onChange={fullfillmentPageChange}
                          bordered
                          scroll={{
                            x: 1000,
                          }}
                          pagination={false}
                        />
                        <Pagination
                          Current={fullfillmentpage}
                          pageSize={fullfillmentsize}
                          onChange={fullfillmentPageChange}
                          total={fullfillmenttotalElements}
                          showSizeChanger={false}
                        />
                      </div>
                    </div>
                  )}
                  {tablelength == 0 && (
                    <div
                      className="d-flex justify-content-center"
                      style={{ height: "80vh" }}
                    >
                      <img
                        src={Nodata}
                        alt="Nodata"
                        className="alignimagecenter"
                      />
                    </div>
                  )}
                </section>
              ),
            },
            {
              label: "Corporate Fullfillment",
              key: "2",
              children: (
                <>
                  <section className="content ">
                    {corptablelength > 0 && (
                      <div className="row">
                        <div className="mt-3  ">
                          <Table
                            columns={corpfullfillmentcolumns}
                            dataSource={corpfullfillmentdataSource}
                            className="custom-table"
                            style={{ padding: "5px" }}
                            onChange={corpfullfillmentPageChange}
                            bordered
                            scroll={{
                              x: 1000,
                            }}
                            pagination={false}
                          />
                          <Pagination
                            Current={corpfullfillmentpage}
                            pageSize={corpfullfillmentsize}
                            onChange={corpfullfillmentPageChange}
                            total={corpfullfillmenttotalElements}
                            showSizeChanger={false}
                          />
                        </div>
                      </div>
                    )}
                    {tablelength == 0 && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "80vh" }}
                      >
                        <img
                          src={Nodata}
                          alt="Nodata"
                          className="alignimagecenter"
                        />
                      </div>
                    )}
                  </section>
                </>
              ),
            },
          ]}
        />
      </div>
    </>
  );
}

export default Admin_CorpDonation;
