import React, { useState, useEffect } from "react";
import UploadService from "../../service/file-upload.service";
import verified from "../../assets/icons/verified.png";
import { Progress, Modal, Button, Tag } from "antd";
import ShowIntrest from "./IntrestDescription";
import DonatenowModel from "./Donatenow";

const CauseModel = ({ visible, onClose, data, showInterestbtn }) => {
  let causeid = data;
  const [dataSource, setDataSource] = useState([]);
  const [showInterestmodel, setShowInterestmodel] = useState(false);
  const [donatenowmodel, setDonatenowmodel] = useState(false);
  const [causeData, setCauseData] = useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [percentage, setpercentage] = useState();
  const [causeId, setCauseId] = useState();
  const [entityId, setEntityId] = useState();
  const causelistformData = new FormData();

  useEffect(() => {
    if (causeid) {
      const causepagedata = new FormData();
      causepagedata.append("causeId", causeid);
      UploadService.create(
        "common/public/noauth/getCauseMicroSiteDetails",
        causepagedata
      ).then((response) => {
        setCauseData(response.data.data);
        setEntityId(response.data.data.causes.entityId);
        setCauseId(response.data.data.causes.causeId);
      });
    }
  }, [causeid]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const showinterest = () => {
    setShowInterestmodel(true);
  };
  const donatemoney = () => {
    setDonatenowmodel(true);
  };
  return (
    <>
      <Modal
        open={visible}
        closeIcon={false}
        centered={true}
        className="custom-modal"
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button
              key="ok"
              className=" btn btn-warning btn-sm ms-1 "
              onClick={donatemoney}
            >
              Donate Now
            </Button>
            {showInterestbtn == true ? (
              <Button
                key="ok"
                className="btn btn-violet btn-sm ms-1 homebtnheight"
                onClick={showinterest}
              >
                Show Interest
              </Button>
            ) : (
              <Tag color="green" className="mx-2 p-1">
                Shown Interest
              </Tag>
            )}
            <Button key="ok" className="btn-red" onClick={onClose}>
              Close
            </Button>
          </div>,
        ]}
      >
        {causeData ? (
          <div style={{ backgroundColor: "#eeeeee" }}>
            <div className="container">
              <div className="row ">
                <div className="container">
                  <div className="row ">
                    <div
                      className="col-lg-6 p-5 wow fadeIn  "
                      data-wow-delay="0.5s"
                    >
                      <h1 className="fw-web txt-warning mb-2 mt-2">
                        {causeData.causes.title.toUpperCase()}
                      </h1>
                      <span className="bg-white ft-10 p-1 rounded-pill text-dark">
                        <img
                          src={verified}
                          className="img-fluid"
                          width="20px"
                        />
                        &nbsp;Verified
                      </span>
                      <h6 className="fw-web txt-warning mt-4 mb-2 subheading">
                        About
                      </h6>
                      <p className="mb-4">{causeData.causes.description}</p>
                      <h6 className="fw-web txt-warning mt-4 mb-2 subheading">
                        Category
                      </h6>
                      <p className="mb-4">{causeData.causes.category}</p>
                    </div>

                    <div className="col-lg-6 p-5  ">
                      <div className="row  ">
                        <div
                          id="carouselExampleIndicators"
                          className="carousel slide"
                          data-bs-ride="carousel"
                        >
                          <div className="carousel-indicators">
                            <button
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide-to="0"
                              className="active"
                              aria-current="true"
                              aria-label="Slide 1"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide-to="1"
                              aria-label="Slide 2"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide-to="2"
                              aria-label="Slide 3"
                            ></button>
                          </div>
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <img
                                src={`https://www.doaramservice.com/ngo/${
                                  causeData.causes.entityId
                                }/cause/${causeData.causes.causeId}/${
                                  causeData.causes.images.split(",")[0]
                                }`}
                                className="d-block w-100 img-fluid websiteimage"
                                alt="..."
                              />
                            </div>
                            <div className="carousel-item">
                              <img
                                src={`https://www.doaramservice.com/ngo/${
                                  causeData.causes.entityId
                                }/cause/${causeData.causes.causeId}/${
                                  causeData.causes.images.split(",")[1]
                                }`}
                                className="d-block w-100 img-fluid websiteimage"
                                alt="..."
                              />
                            </div>
                            <div className="carousel-item">
                              <img
                                src={`https://www.doaramservice.com/ngo/${
                                  causeData.causes.entityId
                                }/cause/${causeData.causes.causeId}/${
                                  causeData.causes.images.split(",")[2]
                                }`}
                                className="d-block w-100 img-fluid websiteimage"
                                alt="..."
                              />
                            </div>
                          </div>
                          <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="container-fluid   mt-3"
              style={{ backgroundColor: " #D9D9D9" }}
            >
              <div className="container ">
                <div className="row g-5 ">
                  <div
                    className="col-lg-4 p-4 wow fadeIn text-start  "
                    data-wow-delay="0.5s"
                  >
                    <h4
                      className="fw-web  mb-2 subheading "
                      style={{ Color: "#2D1621" }}
                    >
                      Address
                    </h4>
                    <p style={{ Color: "#666666 " }}>
                      <i className="fa fa-map-marker"></i>{" "}
                      {causeData.contactInfo.addressLine1}
                    </p>
                  </div>
                  <div
                    className="col-lg-4 p-4 wow fadeIn text-start  "
                    data-wow-delay="0.5s"
                  >
                    <h4
                      className="fw-web subheading mb-2 "
                      style={{ Color: "#2D1621" }}
                    >
                      Contact details
                    </h4>
                    <div className="">
                      <p style={{ Color: "#666666 " }}>
                        <i className="fa fa-envelope"></i>{" "}
                        {causeData.contactInfo.email}
                      </p>
                      <p style={{ Color: "#666666 " }}>
                        <i className="fa fa-phone"></i>{" "}
                        {causeData.contactInfo.phone}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 p-4 wow fadeIn text-start  "
                    data-wow-delay="0.5s"
                  >
                    <h4
                      className="fw-web subheading  mb-2 "
                      style={{ Color: "#2D1621" }}
                    >
                      Goal
                    </h4>
                    <div className="row" style={{ Color: "#666666" }}>
                      <div className="col-md-6">Collection</div>
                      <div className="col-md-6">
                        ₹{causeData.causes.receivedAmount}/
                        {causeData.causes.goalAmount}
                      </div>
                      <Progress
                        percent={parseFloat(
                          (causeData.causes.receivedAmount /
                            causeData.causes.goalAmount) *
                            100
                        ).toFixed(2)}
                      />
                    </div>
                    <div className="row mt-2" style={{ Color: "#666666" }}>
                      <div className="col-md-6">Duration</div>
                      <div className="col-md-6">
                        {formatDate(causeData.causes.startDate)} -{" "}
                        {formatDate(causeData.causes.endDate)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          "LOADING"
        )}
      </Modal>
      <ShowIntrest
        visible={showInterestmodel}
        onClose={() => setShowInterestmodel(false)}
        causeId={causeId}
        entityId={entityId}
      />
      <DonatenowModel
        visible={donatenowmodel}
        onClose={() => setDonatenowmodel(false)}
        donatecauseId={causeId}
        donatengoId={entityId}
      />
    </>
  );
};

export default CauseModel;
