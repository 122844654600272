import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { Helmet } from "react-helmet";
import Joinus from "./shared/joinus/joinus";
import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";
import Testinomial from "./shared/testinomial/testinomial";
import Question from "./shared/question/question";
import S1 from "../doaram/assets/flipster/1.jpg";
import S2 from "../doaram/assets/flipster/2.jpg";
import S3 from "../doaram/assets/flipster/3.jpg";
import S4 from "../doaram/assets/flipster/4.jpg";
import S5 from "../doaram/assets/flipster/5.jpg";
import S6 from "../doaram/assets/flipster/6.jpg";
import S7 from "../doaram/assets/flipster/7.jpg";
import S8 from "../doaram/assets/flipster/8.jpg";
import S9 from "../doaram/assets/flipster/9.jpg";
import S10 from "../doaram/assets/flipster/10.jpg";

import S11 from "../doaram/assets/flipster/11.jpg";
import S22 from "../doaram/assets/flipster/22.jpg";
import S13 from "../doaram/assets/flipster/13.jpg";
import S14 from "../doaram/assets/flipster/14.jpg";
import S15 from "../doaram/assets/flipster/15.jpg";
import S16 from "../doaram/assets/flipster/16.jpg";
import S17 from "../doaram/assets/flipster/17.jpg";
import S18 from "../doaram/assets/flipster/18.jpg";
import S19 from "../doaram/assets/flipster/19.jpg";
import S20 from "../doaram/assets/flipster/20.jpg";
import about from "../imges/h_about.png"

import Blog1 from "../imges/blog/blog1.jpg";
import Blog2 from "../imges/blog/blog2.jpg";
import Blog3 from "../imges/blog/blog3.jpg";
import Blog4 from "../imges/blog/blog4.jpg";
import home1 from "../doaram/assets/flipster/28.jpg"; //../imges/8.jpg
import home2 from "../doaram/assets/flipster/1.jpg"; //../imges/4.jpg
import home3 from "../doaram/assets/flipster/24.jpg"; //../imges/9.jpg
import home4 from "../imges/corp/became_partner.png";
import { Link } from "react-router-dom";
import $ from "jquery";

function Home() {
  window.callfiltster();

  const options = {
    items: 3,
    loop: true,
    autoplay: true,
    nav: false,
    dots: true,
    margin: 8,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
    },
  };

  return (
    <div style={{ backgroundColor: "#eeeeee" }}>
      {/* <Helmet>
        <title>DoAram – Connecting Accredited NGOs with Corporates</title>
        <meta
          name="description"
          content="DoAram enables you to contribute with utmost trust and confidence to NGOs pan India.
           Looking for a cause to donate? You’re at the right place!"
        />
        <link rel="canonical" href="https://www.doaram.com" />
      </Helmet> */}
      <Header />
      {/* HomePage Start */}

      {/* <!--Team Start--> */}
      <div className="container-fluid pt-5">
        <div className="pt-5">
          <div className=" container-xxl py-1 webbody mt-5">
            <div className="container">
              <div
                className="text-center mx-auto wow fadeInUp txthome"
                data-wow-delay="0.1s"
              >
                <h1 className="txt-warning display-5 mt-3 mb-3">
                  {" "}
                  Let's DoAram{" "}
                </h1>
                <p className=" text-center mb-2">
                  (The Tamil word 'Aram' encompasses all the Virtues. It refers
                  to an act of kindness and expression of human love without any
                  personal motive.)
                </p>
                <p className="fw-web text-center mb-2">
                  Join the World's Largest Contribution Platform to make the
                  World a Better Place
                </p>
              </div>
            </div>
          </div>

          <div className="row g-4 pt-2 pb-4">
            <article id="demo-flat" className="demo">
              <div className="flatLanding">
                <ul>
                  <li data-flip-title="Red" data-flip-category="Category 1">
                    <img alt="" className="rounded" src={S1} />
                    <div className="filster-left  text-start">
                      <h2 className="text-white">For NGOs</h2>
                      <p className="ft-5 text-start">
                        Raise Funds & Volunteers for your cause.
                      </p>
                      <Link to="/signup">
                        <button
                          className="btn btn-warning btn-sm"
                          type="button"
                        >
                          NGO Sign Up
                        </button>
                      </Link>
                    </div>
                  </li>
                  <li data-flip-title="Razzmatazz" data-flip-category="Purples">
                    <img alt="" className="rounded" src={S2} />
                    <div className="filster-left  text-start">
                      <h2 className="text-white">For Corporates</h2>
                      <p className="ft-5 text-start">
                        Corporate solutions in one platform
                      </p>
                      <Link to="/corpsignup">
                        <button
                          className="btn btn-warning btn-sm"
                          type="button"
                        >
                          Corporate Sign Up
                        </button>
                      </Link>
                    </div>
                  </li>
                  <li data-flip-title="Deep Lilac" data-flip-category="Purples">
                    <img alt="" className="rounded" src={S3} />
                    <div className="filster-left  text-start">
                      <h2 className="text-white">For Individuals</h2>
                      <p className="ft-5 text-start">
                        Donate / Volunteer For Good
                      </p>
                      <button className="btn btn-warning" type="button">
                        Individual Sign Up
                      </button>
                    </div>
                  </li>
                  <li data-flip-title="Red" data-flip-category="Category 1">
                    <img alt="" className="rounded" src={S4} />
                    <div className="filster-left  text-start">
                      <h2 className="text-white">Join With DoAram Family</h2>
                      <p className="ft-5 text-start">
                        Get in touch with thousands of verified NGOs.
                      </p>
                    </div>
                  </li>
                  <li data-flip-title="Razzmatazz" data-flip-category="Purples">
                    <img alt="" className="rounded" src={S5} />
                    <div className="filster-left  text-start">
                      <h2 className="text-white">Power of Insights</h2>
                      <p className="ft-5 text-start">
                        Empower your NGO. Plan - Develop - Uplift
                      </p>
                    </div>
                  </li>
                  <li data-flip-title="Deep Lilac" data-flip-category="Purples">
                    <img alt="" className="rounded" src={S6} />
                    <div className="filster-left  text-start">
                      <h2 className="text-white">
                        End-to-End digital platform
                      </h2>
                      <p className="ft-5 text-start">
                        Empower your NGO. Plan - Develop - Uplift
                      </p>
                    </div>
                  </li>
                  <li data-flip-title="Red" data-flip-category="Category 1">
                    <img alt="" className="rounded" src={S7} />
                    <div className="filster-left  text-start">
                      {" "}
                      <h2 className="text-white">For NGOs</h2>
                      <p className="ft-5 text-start">
                        Raise Funds & Volunteers for your cause.
                      </p>
                      <Link to="/signup">
                        <button
                          className="btn btn-warning btn-sm"
                          type="button"
                        >
                          NGO Sign Up
                        </button>
                      </Link>
                    </div>
                  </li>
                  <li data-flip-title="Razzmatazz" data-flip-category="Purples">
                    <img alt="" className="rounded" src={S8} />
                    <div className="filster-left text-start">
                      <h2 className="text-white">For Corporates</h2>
                      <p className="ft-5 text-start">
                        Corporate solutions in one platform
                      </p>
                      <Link to="/corpsignup">
                        <button
                          className="btn btn-warning btn-sm"
                          type="button"
                        >
                          Corporate Sign Up
                        </button>
                      </Link>
                    </div>
                  </li>
                  <li data-flip-title="Deep Lilac" data-flip-category="Purples">
                    <img alt="" className="rounded" src={S3} />
                    <div className="filster-left  text-start">
                      <h2 className="text-white">For Individuals</h2>
                      <p className="ft-5">Donate / Volunteer For Good</p>
                      <button className="btn btn-warning" type="button">
                        Individual Sign Up
                      </button>
                    </div>
                  </li>
                  <li data-flip-title="Deep Lilac" data-flip-category="Purples">
                    <img alt="" className="rounded" src={S9} />
                    <div className="filster-left  text-start">
                      {" "}
                      <h2 className="text-white">Donations</h2>
                      <p className="ft-5 text-start">
                        Help provide, build and sustain a better world by
                        donating
                      </p>
                    </div>
                  </li>
                  <li data-flip-title="Daisy Bush" data-flip-category="Purples">
                    <img alt="" className="rounded" src={S10} />
                    <div className="filster-left  text-start">
                      {" "}
                      <h2 className="text-white">Do Good, Feel Right</h2>
                      <p className="ft-5 text-start">
                        Donate / Volunteer For Good
                      </p>
                    </div>
                  </li>
                  <li
                    data-flip-title="Cerulean Blue"
                    data-flip-category="Blues"
                  >
                    <img alt="" className="rounded" src={S11} />
                    <div className="filster-left  text-start">
                      <h2 className="text-white">Volunteer</h2>
                      <p className="ft-5 text-start">
                        Play a vital role in developing the society, improving
                        communities.
                      </p>
                    </div>
                  </li>
                  <li data-flip-title="Dodger Blue" data-flip-category="Blues">
                    <img alt="" className="rounded" src={S22} />
                    <div className="filster-left  text-start">
                      {" "}
                      <h2 className="text-white">Platform</h2>
                      <p className="ft-5 text-start">
                        Monitor Your Funds / Help Employees Volunteer
                      </p>
                    </div>
                  </li>
                  <li data-flip-title="Cyan" data-flip-category="Blues">
                    <img alt="" className="rounded" src={S13} />
                    <div className="filster-left  text-start">
                      <h2 className="text-white">Transparency </h2>
                      <p className="ft-5 text-start">
                        Harness Efforts - Channel Funds - Measure Impact -
                        Respond Quickly
                      </p>
                    </div>
                  </li>
                  <li data-flip-title="Robin's Egg" data-flip-category="Blues">
                    <img alt="" className="rounded" src={S14} />
                    <div className="filster-left  text-start">
                      {" "}
                      <h2 className="text-white">Monetary Management </h2>
                      <p className="ft-5 text-start">
                        Comprehensive methods and dexterous tools for management
                        of CSR Funds
                      </p>
                    </div>
                  </li>
                  <li data-flip-title="Deep Sea" data-flip-category="Greens">
                    <img alt="" className="rounded" src={S15} />
                    <div className="filster-left  text-start">
                      {" "}
                      <h2 className="text-white">Advantage </h2>
                      <p className=" ft-5 text-start">Humanize your brand</p>
                    </div>
                  </li>
                  <li data-flip-title="Apple" data-flip-category="Greens">
                    <img alt="" className="rounded" src={S16} />
                    <div className="filster-left  text-start">
                      <h2 className="text-white">Vision</h2>
                      <p className="ft-5 text-start">
                        Seamless and transparent charity contribution platform
                      </p>
                    </div>
                  </li>
                  <li data-flip-title="Apple" data-flip-category="Greens">
                    <img alt="" className="rounded" src={S17} />
                    <div className="filster-left  text-start">
                      {" "}
                      <h2>Engage your employees</h2>
                      <p className="ft-5 text-start">
                        Who are the pulse of your brand to effect positive
                        social changes.
                      </p>
                    </div>
                  </li>
                  <li data-flip-title="Apple" data-flip-category="Greens">
                    <img alt="" className="rounded" src={S18} />
                    <div className="filster-left  text-start">
                      {" "}
                      <h2>Mission</h2>
                      <p className="ft-5 text-start">
                        To empower the vulnerable sections of society.
                      </p>
                    </div>
                  </li>
                  <li data-flip-title="Apple" data-flip-category="Greens">
                    <img alt="" className="rounded" src={S19} />
                    <div className="filster-left  text-start">
                      {" "}
                      <h2>NGOs</h2>
                      <p className="ft-5 text-start">
                        NGOs that parallel your company's CSR philosophies and
                        brand identity.
                      </p>
                    </div>
                  </li>
                  <li data-flip-title="Apple" data-flip-category="Greens">
                    <img alt="" className="rounded" src={S20} />
                    <div className="filster-left  text-start">
                      {" "}
                      <h2>Corporates</h2>
                      <p className="ft-5 text-start">
                        NGOs connect and receive support from a plethora of
                        corporates and individuals
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </article>
          </div>

          {/* <!-- About Start --> */}
          <div className="container-xxl py-3">
            <div className="container justify-content-center">
              <div className="row g-5">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                  <h1 className="fw-web txt-warning mb-2 abouttxt">
                    About DoAram
                    <br />
                    <small className="h6 txt-warning">DoAram, Feel Good</small>
                  </h1>
                  <p className="mt-4 mb-4">
                    DoAram is an end-to-end digital platform bridging a trifecta
                    of corporates, NGOs and individuals to accomplish their
                    social responsibility goals. Connect with authentic and
                    verified NGOs, Corporates and Individuals willing to make
                    the world a better place. By connecting registered and
                    trusted NGOs with companies and individuals, DoAram aids the
                    transparent and seamless transfer and functioning of
                    funding, volunteering, campaigning, and fundraising, all in
                    one place to realize maximum impact of social good.
                  </p>
                  <div className="row pt-2">
                    <div className="col">
                      <div className="d-flex align-items-left">
                        <Link to="/about">
                          <button type="button" className="btn btn-warning">
                            Learn More
                            <i className="fa fa-arrow-right text-white ms-1"></i>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
              <div className="row align-item-center h-100">
                <div className="wow fadeInUp" data-wow-delay="0.1s">
                  <img
                    className="img-fluid aboutimg"
                    src={about}
                    alt=""
                  />
                </div>
              </div>
            </div>
              </div>
            </div>
          </div>
          {/* <!-- About End --> */}

          <div className="row d-flex justify-content-center pt-2 pb-4 bg-white">
            <div className="col-lg-3 col-md-4">
              <div className="team-item">
                <img alt="" className="img-fluid homeimagetop" src={home2} />
                <div className="d-flex ">
                  <div className=" btn-square bg-warning teamimg homeimageleftbottom">
                    <i className="fa fa-2x fa-share text-white"></i>
                  </div>
                  <div className="position-relative homeimagerightbottom  overflow-hidden bg-light d-flex flex-column justify-content-center w-100 ps-4  containerbox ">
                    <h5>NGOs</h5>
                    <span className="txt-primary fw-bold">
                      Empower your NGO with the Power of Insights!{" "}
                    </span>
                    <div className="team-social">
                      <span>Raise Funds & Volunteers for your cause. </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="team-item">
                <img alt="" className="img-fluid homeimagetop" src={home3} />
                <div className="d-flex">
                  <div className="flex-shrink-0 btn-square bg-warning teamimg homeimageleftbottom">
                    <i className="fa fa-2x fa-share text-white"></i>
                  </div>
                  <div className="containerbox homeimagerightbottom position-relative overflow-hidden bg-light d-flex flex-column justify-content-center w-100 ps-4">
                    <h5>Corporates</h5>
                    <span className="txt-primary fw-bold">
                      {" "}
                      Contribute / Volunteer to the cause of your choice
                    </span>
                    <div className="team-social">
                      <span>Monitor Your Funds / Help Employees Volunteer</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="team-item">
                <img alt="" className="img-fluid homeimagetop" src={home1} />
                <div className="d-flex">
                  <div className="flex-shrink-0 btn-square bg-warning teamimg homeimageleftbottom">
                    <i className="fa fa-2x fa-share text-white"></i>
                  </div>
                  <div className=" containerbox homeimagerightbottom position-relative overflow-hidden bg-light d-flex flex-column justify-content-center w-100 ps-4">
                    <h5>Individuals</h5>
                    <span className="txt-primary fw-bold">
                      Contribute / Volunteer to the cause of your choice{" "}
                    </span>
                    <div className="team-social">
                      <span>Donate and/or Volunteer </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Team End --> */}

      {/* <!-- Facts Start --> */}
      <div className="container-fluid my-3 p-5 bg-rate">
        <div className="row g-5">
          <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.1s">
            <div className="text-left mt-5 pt-5">
              <h1 className="h3 text-white mb-0">DoAram in Numbers</h1>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.3s">
            <div className="text-center border p-5">
              <i className="fa fa-users-cog fa-3x text-white mb-3"></i>
              <h1 className="txt-warning mb-0" data-toggle="counter-up">
                14,000
              </h1>
              <span className="fs-5 fw-semi-bold text-white">NGOs</span>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.5s">
            <div className="text-center border p-5">
              <i className="fa fa-cubes fa-3x text-white mb-3"></i>
              <h1 className="txt-warning mb-0" data-toggle="counter-up">
                235
              </h1>
              <span className="fs-5 fw-semi-bold text-white">Causes</span>
            </div>
          </div>
          <div className="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.7s">
            <div className="text-center border p-5">
              <i className="fa fa-building fa-3x text-white mb-3"></i>
              <h1 className="txt-warning mb-0" data-toggle="counter-up">
                100+
              </h1>
              <span className="fs-5 fw-semi-bold text-white">Corporates</span>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Facts End --> */}
      <Joinus />

      {/* <!-- Facts Start --> */}
      <div className="container-fluid bg-yellowgra mt-3">
        <div className="row ">
          <div className="container">
            <div className="row m-5">
              <div
                className="col-md-6 col-xl-6 wow fadeIn"
                data-wow-delay="0.1s"
              >
                <div className="text-left ">
                  <h1 className="h3 text-white mb-2">Why DoAram</h1>

                  <p className="fw-web text-dark mb-2">
                    We are geared with a comprehensive, end to end software
                    solutions that provides authentic, transparent and seamless
                    transactions between corporates, individuals and NGOs.{" "}
                  </p>
                  <p className="fw-web text-dark mb-2">
                    Harness Efforts - Channel Funds - Measure Impact - Respond
                    Quickly
                  </p>
                  <a className="ngoPanel">
                    <Link to="/signup">
                      <button type="button" className="btn btn-secondary">
                        Sign-up Now
                      </button>
                    </Link>
                  </a>
                </div>
              </div>
              <div
                className="col-md-6 col-xl-6 wow fadeIn"
                data-wow-delay="0.3s"
              >
                <div
                  className="d-flex justify-content-center wow fadeInUp mt-2"
                  data-wow-delay="0.1s"
                >
                  <img alt="" className="img-fluid factimg" src={home4} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Facts End --> */}
      {/* testinomial */}
      {/* <Testinomial /> */}
      {/* <!-- blog Start --> */}
      <div className="container-xxl py-3">
        <div className="text-center mx-auto wow fadeIn " data-wow-delay="0.1s">
          <h1 className=" mb-3">
            Our Articles
            <Link to="/blog" className="btn btn-warning float-end">
              View All
              <i className="fa fa-arrow-right ms-1"></i>
            </Link>
          </h1>
        </div>
      </div>

      <div className="container-fluid">
        <OwlCarousel className="owl-theme" {...options}>
          {/* <div className="post-slide ">
            <div className="post-img">
              <img src={Blog1} alt="Blog1" className="blogheight" />
              <div className="over-layer">
                <ul className="post-link">
                  <li>
                    <Link to="#" className="fa fa-search"></Link>
                  </li>
                  <li>
                    <Link to="#" className="fa fa-link"></Link>
                  </li>
                </ul>
              </div>
       
            </div>
            <div className="post-content">
              <h4 className="txt-primary ft-14 text-uppercase ">Project Title 1</h4>

              <p className="post-description">
                We focus on providing healthy and nutritious food to 1800+ school children spread across 38 government schools which are located in and around Harohalli Taluk. We purely operate on donations and CSR funds. We serve free breakfast 5 days a week.
              </p>
              <small class="txt-warning ft-10 ">Ends at : 2024-05-16</small>
              <div className="col mt-4">
                <div class="goal-raised-container">
                  <small className="ft-12 txt-dark goal"><i className="fas fa-chart-pie text-danger"></i>&nbsp; Goal: 500000</small>
                  <small className="ft-12 txt-dark raised"><i className="fas fa-fist-raised text-green"></i>&nbsp;Raised: 0</small>
                </div>
              </div>
              <div className="col mt-4">
                <div className=" btn-group-sm" role="group" aria-label="...">
                  <button type="button" class="ant-btn css-190m0jy ant-btn-default btn btn-secondary btn-lg custom-button">
                    <span> </span><i class="fa fa-handshake" aria-hidden="true"></i><span>&nbsp; Donate</span>
                  </button>
                  <button type="button" class="ant-btn css-190m0jy ant-btn-default btn btn-warning btn-lg custom-button">
                    <i className="fa fa-eye" aria-hidden="true"></i><span>&nbsp;View</span>
                  </button>
                </div>
              </div>

            </div>
          </div> */}
          <div className="post-slide">
            <div className="post-img">
              <img src={Blog2} alt="Blog2" className="blogheight" />
              <div className="over-layer">
                <ul className="post-link">
                  <li>
                    <Link to="#" className="fa fa-search"></Link>
                  </li>
                  <li>
                    <Link to="#" className="fa fa-link"></Link>
                  </li>
                </ul>
              </div>
              <div className="post-date">
                <span className="date">1</span>
                <span className="month">Apr</span>
              </div>
            </div>
            <div className="post-content">
              <h3 className="post-title">
                <Link to="/blog/moral-science-a-bygone-subject-in-schools-should-be-made-mandatory">
                  Moral science, a bygone subject in schools should be made
                  mandatory
                </Link>
              </h3>
              <p className="post-description">
                Moral education is a value added learning which helps the
                children to develop a good moral and civic sense to serve the
                society well. A decade ago, there existed a separate subject
                called moral science in almost all the schools be it private or
                a public sector. Sadly, this substantial subject was removed
                from the curriculum as our education system ....
              </p>

              <Link
                to="/blog/moral-science-a-bygone-subject-in-schools-should-be-made-mandatory"
                className="read-more"
              >
                read more
              </Link>
            </div>
          </div>
          <div className="post-slide">
            <div className="post-img">
              <img src={Blog4} alt="Blog4" className="blogheight" />
              <div className="over-layer">
                <ul className="post-link">
                  <li>
                    <Link to="#" className="fa fa-search"></Link>
                  </li>
                  <li>
                    <Link to="#" className="fa fa-link"></Link>
                  </li>
                </ul>
              </div>
              <div className="post-date">
                <span className="date">6</span>
                <span className="month">Dec</span>
              </div>
            </div>
            <div className="post-content">
              <h3 className="post-title">
                <Link to="/blog/foot-binding-was-a-symbol-of-status-modesty-and-moral-virtue">
                  Foot binding was a symbol of status, modesty and moral virtue
                </Link>
              </h3>
              <p className="post-description">
                Foot binding was one of the unique, ancient practices amongst
                the upper class women in China. It started as a fashionable
                impulse during the Tang dynasty where one of the emperor&rsquo;s
                concubines named Yao Niang performed dance in the court. She
                entranced the emperor Li Yu dancing...
              </p>
              <Link
                to="/blog/foot-binding-was-a-symbol-of-status-modesty-and-moral-virtue"
                className="read-more"
              >
                read more
              </Link>
            </div>
          </div>
          <div className="post-slide">
            <div className="post-img">
              <img src={Blog3} alt="Blog3" className="blogheight" />
              <div className="over-layer">
                <ul className="post-link">
                  <li>
                    <Link to="#" className="fa fa-search"></Link>
                  </li>
                  <li>
                    <Link to="#" className="fa fa-link"></Link>
                  </li>
                </ul>
              </div>
              <div className="post-date">
                <span className="date">26</span>
                <span className="month">Jan</span>
              </div>
            </div>
            <div className="post-content">
              <h3 className="post-title">
                <Link to="#">
                  Self-help group: the role of NGOs in promoting SHGs and its
                  scope.
                </Link>
              </h3>
              <p className="post-description">
                A self-help group is an independent voluntary association formed
                by women from the same socio-economic background who come
                together to the purpose of solving their common problems. The
                origin of SHG dates back to 1975 founded by professor Mohammed
                Yunus, a Nobel laureate ...
              </p>
              <Link to="#" className="read-more">
                read more
              </Link>
            </div>
          </div>
        </OwlCarousel>
      </div>
      {/* Homepage End */}
      <Footer />
    </div>
  );
}

export default Home;
