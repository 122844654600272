import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./doaram_web/home.jsx";
import About from "./doaram_web/about.jsx";
import Corporate from "./doaram_web/corporatesolution.jsx";
import Individuals from "./doaram_web/individuals.jsx";
import Contact from "./doaram_web/contact.jsx";
import Blog from "./doaram_web/blog.jsx";
import FAQ from "./doaram_web/faq.jsx";
import Insight from "./doaram_web/insight.jsx";
import Listedngo from "./doaram_web/listedngo.jsx";
import Policy from "./doaram_web/shared/policy&terms/policy.jsx";
import Terms from "./doaram_web/shared/policy&terms/terms.jsx";
import ScrollToTop from "./doaram_web/scrolltotop.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import NGOLogin from "./doaram/components/ngo/NGO_Login";
import CorpLogin from "./doaram/components/corp/Corp_Login";
import AdminLogin from "./doaram/components/admin/Admin_Login";
import Signup from "./doaram/components/ngo/Signup";
import CorpSignup from "./doaram/components/corp/Corp_Signup";
import CorpSignUpSelector from "./doaram/components/corp/Corp_SignUpSelector";
import NGOCause from "./doaram/components/ngo/NGO_Cause";
import CauseDetails from "./doaram/components/ngo/CauseDetails";
import NGOUpdateCause from "./doaram/components/ngo/NGO_UpdateCause.jsx";
import NGOProfile from "./doaram/components/ngo/NGO_Profile";
import NGOPlan from "./doaram/components/ngo/NGO_Plan";
import Error404 from "./doaram/components/ngo/Error404";
import ForgotPassword from "./doaram/components/shared/ForgotPassword";
import CorpNGOs from "./doaram/components/ngo/Corp_NGOs";
import Notification from "./doaram/components/ngo/Notification";
import TermsConditions from "./doaram/components/ngo/TermsConditions";
import AdminCause from "./doaram/components/admin/Admin_Cause";
import AdminProfile from "./doaram/components/admin/Admin_Profile";
import AdminNGO from "./doaram/components/admin/Admin_NGO";
import CorpNGO from "./doaram/components/corp/Corp_NGO";
import CorpProfile from "./doaram/components/corp/Corp_Profile";
import NGODashboard from "./doaram/components/ngo/NGO_Dashboard";
import CorpDashboard from "./doaram/components/corp/Corp_Dashboard";
import AdminDashboard from "./doaram/components/admin/Admin_Dashboard";
import SignUpSelectors from "./doaram/components/ngo/SignupSelectors";
import VerifyEmail from "./doaram/components/ngo/VerifyEmail";
import AccountVerify from "./doaram/components/ngo/AccountVerify";
import AdminViewCause from "./doaram/components/admin/Admin_ViewCause";
import AdminViewProfile from "./doaram/components/admin/Admin_ViewProfile";
import ResetPassword from "./doaram/components/shared/ResetPassword";
import AdminForgotPassword from "./doaram/components/admin/Admin_ForgotPassword";
import AdminResetPassword from "./doaram/components/admin/Admin_ResetPassword";
import BlogDetails1 from "./doaram_web/blog1.jsx";
import BlogDetails2 from "./doaram_web/blog2";
import BlogDetails3 from "./doaram_web/blog3";
import BlogDetails4 from "./doaram_web/blog4";
import "../src/css/style.css";
import SideMenu from "./doaram/components/shared/SideMenu";
import SideMenuadmin from "./doaram/components/shared/SideMenuadmin";
import SideMenucorp from "./doaram/components/shared/SideMenucorp";
import SignedinUpgrade from "./doaram/components/ngo/SignedinUpgrade.jsx";
import AdminMembershipProfile from "./doaram/components/admin/Admin_MembershipProfile.jsx";
import AdminNGOMembership from "./doaram/components/admin/Admin_NGOMembership.jsx";
import IndividualLogin from "./doaram/components/individual/Individual_Login.jsx";
import ListedCause from "./doaram_web/listedCause.jsx";
import Additionalinfo from "./doaram/components/ngo/additionalinfo.jsx";
import IndividualDonation from "./doaram/components/individual/Individual_UserDetails.jsx";
import AdminDonation from "./doaram/components/admin/Admin_Donation.jsx";
import NGODonation from "./doaram/components/ngo/NGO_Donation.jsx";
import NGOMembershipstatus from "./doaram/components/ngo/NGO_Membershipstatus.jsx";
import CauseAdditionalinfo from "./doaram/components/ngo/NGO_CauseAddinfo.jsx";
import AdminPaymentStatus from "./doaram/components/admin/Admin_PaymentStatus.jsx";
import IndividualSignUpSelectors from "./doaram/components/individual/Individual_SignupSeletor.jsx";
import IndividualSignup from "./doaram/components/individual/Individual_Signup.jsx";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store.js";
import AdminCorp from "./doaram/components/admin/Admin_Corp.jsx";
import CorpAccountVerify from "./doaram/components/corp/Corp_AccountVerify.jsx";
import AdminViewCorp from "./doaram/components/admin/Admin_ViewCorp.jsx";
import CorpAddinfo from "./doaram/components/corp/Corp_Addinfo.jsx";
import CorpNGOCause from "./doaram/components/corp/Corp_NGOCause.jsx";
import CorpAccountSucess from "./doaram/components/corp/Corp_SignupSucess.jsx";
import CauseMicrosite from "./doaram_web/causewebsite.jsx";
import AdminCorpIntrest from "./doaram/components/admin/Admin_ShownIntrest.jsx";
import AdminCorpIntrestedprofile from "./doaram/components/admin/Admin_ProfileIntrested.jsx";
import AdminCorpList from "./doaram/components/admin/Admin_CorpIntrestedlist.jsx";
import PublicNgoMicrosite from "./doaram_web/publicngowebsite.jsx";
import PublicCauseMicrosite from "./doaram_web/Publiccausewebsite.jsx";
import NGOMicrosite from "./doaram_web/ngowebsite.jsx";
import CorpCause from "./doaram/components/corp/Corp_Cause.jsx";
import CorpPayments from "./doaram/components/corp/Corp_Payment.jsx";
import AdminCorpDonation from "./doaram/components/admin/Admin_CorpDonation.jsx";
import IndividualSideMenu from "./doaram/components/shared/SideMenuIndividual.jsx";
import IndividualDashboard from "./doaram/components/individual/Individual_Dashboard.jsx";
import IndividualProfile from "./doaram/components/individual/Individual_Profile.jsx";
import CorpDonation from "./doaram/components/corp/Corp_Donation.jsx";
import IndividualVerifyemail from "./doaram/components/individual/Individual_VerifyEmail.jsx";
import NGO80GCertificate from "./doaram/components/ngo/NGO_80GCertificate.jsx";
import ConceptNote from "./doaram/components/ngo/ConceptNote.jsx";
import Corp_ConcepNote from "./doaram/components/corp/Corp_ConcepNote.jsx";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {/* ....................Public Routes.................. */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/corporate" element={<Corporate />} />
            <Route path="/individuals" element={<Individuals />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog1" element={<BlogDetails1 />} />
            <Route path="/blog2" element={<BlogDetails2 />} />
            <Route path="/blog3" element={<BlogDetails3 />} />
            <Route path="/blog4" element={<BlogDetails4 />} />
            <Route
              path="/ngo/:ngoname/:ngoid"
              element={<PublicNgoMicrosite />}
            />
            <Route
              path="/cause/:causename/:causeid"
              element={<PublicCauseMicrosite />}
            />
            <Route path="corp/ngo/:ngoname/:ngoid" element={<NGOMicrosite />} />
            <Route
              path="corp/cause/:causename/:causeid"
              element={<CauseMicrosite />}
            />
            <Route path="/listedngo" element={<Listedngo />} />
            {/* <Route path="/registeredngo" element={<Registeredngo />} /> */}
            <Route path="/insight" element={<Insight />} />
            <Route path="/cause" element={<ListedCause />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/terms" element={<Terms />} />
            {/* ----------------------NGO---------------------------- */}
            <Route path="/ngosignin" element={<NGOLogin />} />
            <Route path="/corpsignin" element={<CorpLogin />} />
            <Route path="/adminsignin" element={<AdminLogin />} />
            <Route path="/individualsignin" element={<IndividualLogin />} />
            <Route
              path="/individual/causeDonation"
              element={<IndividualDonation />}
            />
            <Route
              path="/individualsignupselector"
              element={<IndividualSignUpSelectors />}
            />

            <Route path="/individualsignup" element={<IndividualSignup />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/emailsent" element={<CorpAccountSucess />} />
            <Route path="/corpsignup" element={<CorpSignup />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route
              path="/adminforgotPassword"
              element={<AdminForgotPassword />}
            />
            <Route
              path="/adminresetPassword"
              element={<AdminResetPassword />}
            />
            <Route path="/SignUpSelector" element={<SignUpSelectors />} />
            {/* <Route path="/ngoprofiles" element={<NGO_Profiles />} /> */}
            <Route
              path="/corpSignUpSelector"
              element={<CorpSignUpSelector />}
            />
            {/* ...............................Protected Routes.................. */}
            \{" "}
            <Route
              path="/ngoDashboard"
              element={
                <SideMenu>
                  <NGODashboard />
                </SideMenu>
              }
            />
           <Route path="/ngo/conceptnote" element={  <SideMenu><ConceptNote /></SideMenu>} />

            <Route
              path="/NGO_Cause"
              element={
                <SideMenu>
                  <NGOCause />
                </SideMenu>
              }
            />
            <Route
              path="/Corp_NGOs"
              element={
                <SideMenu>
                  <CorpNGOs />
                </SideMenu>
              }
            />
            <Route
              path="/CauseDetails"
              element={
                <SideMenu>
                  <CauseDetails />
                </SideMenu>
              }
            />
            <Route
              path="/ngocauseDonation"
              element={
                <SideMenu>
                  <NGODonation />
                </SideMenu>
              }
            />
            <Route
              path="/ngomembershipstatus"
              element={
                <SideMenu>
                  <NGOMembershipstatus />
                </SideMenu>
              }
            />
            <Route
              path="/80gcerticate"
              element={
                <SideMenu>
                  <NGO80GCertificate />
                </SideMenu>
              }
            />
            <Route
              path="/UpdateCause"
              element={
                <SideMenu>
                  <NGOUpdateCause />
                </SideMenu>
              }
            />
            <Route
              path="/NGO_Profile"
              element={
                <SideMenu>
                  <NGOProfile />
                </SideMenu>
              }
            />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/Upgrade" element={<NGOPlan />} />
            <Route
              path="/ngoUpgrade"
              element={
                <SideMenu>
                  <SignedinUpgrade />{" "}
                </SideMenu>
              }
            />
            <Route path="/TermsConditions" element={<TermsConditions />} />
            <Route
              path="/accounts/additional_info"
              element={<Additionalinfo />}
            />
            <Route
              path="/cause/additional_info"
              element={<CauseAdditionalinfo />}
            />
            {/* ...............................ADMIN.................. */}
            <Route
              path="/adminDashboard"
              element={
                <SideMenuadmin>
                  <AdminDashboard />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_Cause"
              element={
                <SideMenuadmin>
                  <AdminCause />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_Profile"
              element={
                <SideMenuadmin>
                  <AdminProfile />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_NGO"
              element={
                <SideMenuadmin>
                  <AdminNGO />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_ViewCorp"
              element={
                <SideMenuadmin>
                  <AdminViewCorp />
                </SideMenuadmin>
              }
            />
            <Route
              path="/AdminViewCause"
              element={
                <SideMenuadmin>
                  {" "}
                  <AdminViewCause />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_Corp"
              element={
                <SideMenuadmin>
                  {" "}
                  <AdminCorp />
                </SideMenuadmin>
              }
            />
            <Route
              path="/AdminViewProfile"
              element={
                <SideMenuadmin>
                  <AdminViewProfile />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_CorpDonation"
              element={
                <SideMenuadmin>
                  <AdminCorpDonation />
                </SideMenuadmin>
              }
            />
            <Route
              path="/NGOMembership"
              element={
                <SideMenuadmin>
                  <AdminNGOMembership />
                </SideMenuadmin>
              }
            />
            <Route
              path="/admincauseDonation"
              element={
                <SideMenuadmin>
                  <AdminDonation />
                </SideMenuadmin>
              }
            />
            <Route
              path="/MembershipProfile"
              element={
                <SideMenuadmin>
                  <AdminMembershipProfile />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_CorpInterest"
              element={
                <SideMenuadmin>
                  <AdminCorpIntrest />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_CorpIntrestedProfile"
              element={
                <SideMenuadmin>
                  <AdminCorpIntrestedprofile />
                </SideMenuadmin>
              }
            />
            <Route
              path="/Admin_CorpInterstedList"
              element={
                <SideMenuadmin>
                  <AdminCorpList />
                </SideMenuadmin>
              }
            />
            <Route
              path="/paymentStatus"
              element={
                <SideMenuadmin>
                  <AdminPaymentStatus />
                </SideMenuadmin>
              }
            />
            {/* ...............................CORPS.................. */}
            <Route
              path="/corpDashboard"
              element={
                <SideMenucorp>
                  <CorpDashboard />
                </SideMenucorp>
              }
            />
            <Route
              path="/Corp_Profile"
              element={
                <SideMenucorp>
                  <CorpProfile />
                </SideMenucorp>
              }
            />
            <Route
              path="/Corp_NGO"
              element={
                <SideMenucorp>
                  <CorpNGO />
                </SideMenucorp>
              }
            />
            <Route path="/Corp_Payment" element={<CorpPayments />} />
            <Route
              path="/Corp_Cause"
              element={
                <SideMenucorp>
                  <CorpCause />
                </SideMenucorp>
              }
            />
            <Route
              path="/Corp_NGOCause"
              element={
                <SideMenucorp>
                  <CorpNGOCause />
                </SideMenucorp>
              }
            />
            <Route
              path="/Corp_Donation"
              element={
                <SideMenucorp>
                  <CorpDonation />
                </SideMenucorp>
              }
            />
            <Route
              path="/corp/conceptnote"
              element={
                <SideMenucorp>
                  <Corp_ConcepNote />
                </SideMenucorp>
              }
            />
            <Route
              path="corp/accounts/additional_info"
              element={<CorpAddinfo />}
            />
            <Route
              path="/accounts/corp/verify_email"
              element={<CorpAccountVerify />}
            />
            <Route path="/accounts/verify_email/" element={<VerifyEmail />} />
            <Route
              path="/accounts/account_verify/"
              element={<AccountVerify />}
            />
            <Route
              path="/accounts/reset_password"
              element={<ResetPassword />}
            />
            {/* ------------------Individuals------------------------ */}
            <Route
              path="/Individual_Dashboard"
              element={
                <IndividualSideMenu>
                  <IndividualDashboard />
                </IndividualSideMenu>
              }
            />
            <Route
              path="/Individual_Profile"
              element={
                <IndividualSideMenu>
                  <IndividualProfile />
                </IndividualSideMenu>
              }
            />
            <Route
              path="/accounts/individual/verify_email"
              element={<IndividualVerifyemail />}
            />
            {/* ...............................Error.................. */}
            <Route path="*" element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
