import React from "react";
import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";
import Question from "./shared/question/question";
import { Helmet } from "react-helmet";

function FAQ() {
  return (
    <>
      {/* <Helmet>
        <title>Faqs - Ngo Registration</title>
        <meta
          name="description"
          content="
          Frequently asked Questions  1.) What is NGO ?? ...2.) What are the types of NGO ??...3.) What are the different Acts in which NGO is registered ??..4.) When is an NGO eligible for Government funding ??...5.) What is CSR ??"
        />
        <link rel="canonical" href="https://www.doaram.com/faq" />
      </Helmet> */}
      <Header />
      <div style={{ backgroundColor: "#eeeeee" }}>
        <div className="container-xxl py-5 webbody">
          <div className="container">
            <div className="row g-5 mt-5">
              <Question />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default FAQ;
