import React, { useState } from "react";
import { Modal, Input, Button, Form } from "antd";
import $ from "jquery";
import UploadService from "../../service/file-upload.service";

const ShowIntrest = ({
  visible,
  onClose,
  causeId,
  entityId,
  footerContent,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));
  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );
  const ShowInterestformData = new FormData();
  function formatDate(date) {
    const d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    return [year, month.padStart(2, "0"), day.padStart(2, "0")].join("-");
  }
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const onsubmit = (value) => {
    const corpRegisterInterest = {
      id: null,
      corpId: userCred.associatedEntity,
      ngoId: entityId,
      causeId: causeId,
      interestRegDate: formatDate(new Date()),
      corpComments: value.reason,
      adminActionStartDate: null,
      adminComments: null,
      isDonationProvided: null,
      status: null,
      closedDate: null,
    };

    ShowInterestformData.append("userCred", JSON.stringify(userCred));
    ShowInterestformData.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );
    ShowInterestformData.append(
      "corpRegisterInterest",
      JSON.stringify(corpRegisterInterest)
    );

    UploadService.create(
      "corp/cause/registerInterest",
      ShowInterestformData
    ).then((response) => {
      if (response.data.httpStatus == "OK") {
        setIsModalOpen(true);
        onClose();
      }
    });
  };
  return (
    <>
      <Modal
        open={visible}
        closeIcon={false}
        centered={true}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button key="ok" type="primary" onClick={onClose}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <Form scrollToFirstError={true} name="validateOnly" onFinish={onsubmit}>
          <p>Plese provide the Description.</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Reason is required",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                placeholder="Reason for the Additional  in 100 words"
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <Button htmlType="submit" className="bg-yellowgra">
              Send +
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        open={isModalOpen}
        closeIcon={false}
        onOk={handleOk}
        centered={true}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button key="ok" type="primary" onClick={handleOk}>
              OK
            </Button>
          </div>,
        ]}
      >
        {" "}
        <div className="d-flex justify-content-center">
          <img
            src={require("./../../assets/icons/check-icon.png")}
            alt="sucess-icon"
            className="sucessiconimg"
          />
        </div>
        <div className="d-flex justify-content-center mt-3 text-center">
          Thanks for showing Interest for this cause,Our Admin Team will Contact
          you soon.
        </div>
        <div className="d-flex justify-content-center mt-2 b"> Thank you!</div>
      </Modal>
    </>
  );
};

export default ShowIntrest;
