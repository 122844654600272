import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UploadService from "../doaram/service/file-upload.service";
import { State, City } from "country-state-city";
import { Button, Form, Select, Input } from "antd";
import Header from "./shared/header/header";
import Footer from "./shared/footer/footer";
import { Progress } from "antd";
import { Pagination } from "antd";
import { useDispatch } from "react-redux";
import Nodata from "../../src/doaram/assets/icons/Nocause.png";

const { Option } = Select;

const ListedCause = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(6);
  const [causeList, setCauseList] = useState([]);
  const [totalElements, setTotalElements] = useState();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  let tablelength = causeList.length;

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    const indianStates = State.getStatesOfCountry("IN").map(
      (state) => state.name
    );
    setStates(indianStates);

    const listedcauseformData = new FormData();
    listedcauseformData.append("page", page - 1);
    listedcauseformData.append("size", size);

    UploadService.create(
      "/common/public/noauth/getAllCauses",
      listedcauseformData
    ).then((response) => {
      setCauseList(response.data.data.causes);
      setTotalElements(response.data.data.totalElements);
    });
  }, [page]);
  const handleStateChange = (value) => {
    setSelectedState(value);

    const selectedStateInfo = State.getStatesOfCountry("IN").find(
      (state) => state.name === value
    );
    if (selectedStateInfo) {
      const citiesData = City.getCitiesOfState(
        selectedStateInfo.countryCode,
        selectedStateInfo.isoCode
      ).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(citiesData);
    } else {
      setCities([]);
    }

    form.setFieldsValue({ city: "" });
    setSelectedCity("");
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  const onFinish = (values) => {
    const causefilterformData = new FormData();
    if (values.name !== undefined) {
      causefilterformData.append("name", values.name);
    }
    if (values.state !== undefined) {
      causefilterformData.append("state", values.state);
    }
    if (values.city !== undefined) {
      causefilterformData.append("city", values.city);
    }
    if (values.rating !== undefined) {
      causefilterformData.append("rating", values.rating);
    }
    if (values.category !== undefined) {
      causefilterformData.append("category", values.category);
    }
    causefilterformData.append("page", page - 1);
    causefilterformData.append("size", size);

    UploadService.create(
      "/common/public/noauth/getAllCauses",
      causefilterformData
    ).then((response) => {
      setCauseList(response.data.data.causes);
      setTotalElements(response.data.data.totalElements);
    });
  };
  const userDonation = (applicantData) => {
    const ngoDetails = {
      ngoId: applicantData.entityId,
      causeId: applicantData.causeId,
    };
    navigate("/individual/causeDonation", {
      state: {
        viewdata: ngoDetails,
      },
    });
  };

  const dynamiccausesite = (causeid, causename) => {
    const causeSlug = causename.toLowerCase().replace(/\s+/g, "-");
    navigate(`/cause/${causeSlug}/${causeid}`);
  };
  return (
    <>
      <div style={{ backgroundColor: "#eeeeee" }}>
        <Header />
        {/* <!-- Facts Start --> */}
        <div className="container-fluid mt-5">
          <div className="mb-3 webbody pt-5">
            <div className="container-fluid pt-5 ">
              <div className="row g-5 pt-1">
                <div className="col-md-3 bg-light">
                  <div className="card-body ">
                    <div className="col-md-12 pt-1">
                      <p>
                        <i className="fa fa-search"></i>&nbsp;Listed Causes -
                        Search By:
                      </p>
                      <hr />
                      <Form
                        onFinish={onFinish}
                        form={form}
                        labelCol={{
                          span: 8,
                        }}
                        wrapperCol={{
                          span: 16,
                        }}
                        style={{
                          maxWidth: 600,
                        }}
                      >
                        <div className="row">
                          <Form.Item name="name" label="Name">
                            <Input placeholder="NGO Name" />
                          </Form.Item>
                        </div>
                        <div className="row">
                          <Form.Item name="state" label="State">
                            <Select
                              placeholder="Select State"
                              onChange={handleStateChange}
                              value={selectedState}
                              showSearch
                            >
                              {states.map((state) => (
                                <Option key={state} value={state}>
                                  {state}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="row">
                          <Form.Item name="city" label="City">
                            <Select
                              placeholder="Select City"
                              onChange={handleCityChange}
                              showSearch
                              value={selectedCity}
                              disabled={!selectedState}
                            >
                              {cities.map((city) => (
                                <Option key={city.value} value={city.value}>
                                  {city.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="row">
                          <Form.Item
                            className="para b themeCol-orange"
                            name="rating"
                            label="Rating"
                          >
                            <Select
                              isClearable
                              placeholder="--Select Rating--"
                              options={[
                                { value: "NGO_BRONZE", label: "Bronze" },
                                { value: "NGO_SILVER", label: "Silver" },
                                { value: "NGO_GOLD", label: "Gold" },
                                { value: "NGO_PLATINUM", label: "Platinum" },
                              ]}
                            />
                          </Form.Item>
                        </div>
                        <div className="row">
                          <Form.Item
                            className="para b themeCol-orange"
                            name="category"
                            label="Category"
                          >
                            <Select
                              isClearable
                              placeholder="--NGO Category--"
                              value={selectedItems}
                              onChange={setSelectedItems}
                              options={[
                                { value: "Elderly", label: "Elderly" },
                                { value: "Animals", label: "Animals" },
                                { value: "Children", label: "Children" },
                                {
                                  value: "Cultural Heritage",
                                  label: "Cultural Heritage",
                                },
                                { value: "Education", label: "Education" },
                                { value: "Employment", label: "Employment" },
                                { value: "Environment", label: "Environment" },
                                {
                                  value: "Disaster Relief Works",
                                  label: "Disaster Relief Works",
                                },
                                {
                                  value: "Women Empowerment",
                                  label: "Women Empowerment",
                                },
                                {
                                  value: "Rural Development",
                                  label: "Rural Development",
                                },
                                {
                                  value: "Community Mobilization",
                                  label: "Community Mobilization",
                                },
                                {
                                  value: "Sustainable Livelihood",
                                  label: "Sustainable Livelihood",
                                },
                                {
                                  value: "Differently Abled",
                                  label: "Differently Abled",
                                },
                                { value: "Others", label: "Others" },
                              ]}
                            />
                          </Form.Item>
                        </div>
                        <div className="d-flex justify-content-end">
                          {/* <NavLink to="/ngosignin"> */}
                          <Button
                            htmlType="submit"
                            className="btnnca btn-gold signupbtn"
                            style={{ width: "90px" }}
                          >
                            <i className="fa fa-filter">&nbsp;Filter</i>
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="col-md-12 mt-3 ">
                    {/* <p>All Approved Cause List</p> */}
                    {tablelength > 0 && (
                      <>
                        <div>
                          <div className="row">
                            {causeList.map(function (applicant) {
                              return (
                                <div className="col-md-4 mb-3">
                                  <div
                                    className="card"
                                    style={{ height: "400px" }}
                                    onClick={() =>
                                      dynamiccausesite(
                                        applicant.causeId,
                                        applicant.title
                                      )
                                    }
                                  >
                                    <img
                                      src={`https://www.doaram.com/ngo/${
                                        applicant.entityId
                                      }/cause/${applicant.causeId}/${
                                        applicant.images.split(",")[0]
                                      }`}
                                      className="card-img-top"
                                      style={{
                                        height: "150px",
                                      }}
                                      alt="..."
                                    />
                                    <div className="card-body">
                                      <h5
                                        className="txt-primary ft-14 text-uppercase mb-2 "
                                        style={{ height: "15px" }}
                                      >
                                   
                                        {applicant.title &&
                                        applicant.title.length > 50
                                          ? `${applicant.title.substring(
                                              0,
                                              38
                                            )}...`
                                          : applicant.title}
                                      </h5>

                                      <p
                                        className="text-dark ft-10"
                                        style={{ height: "25px" }}
                                      >
                                        {applicant.description &&
                                        applicant.description.length > 90
                                          ? `${applicant.description.substring(
                                              0,
                                              100
                                            )}...`
                                          : applicant.description}
                                      </p>
                                      <small class="txt-warning ft-10">
                                        Ends at : {applicant.endDate}
                                      </small>
                                      <div className="col mb-3">
                                        <Progress
                                          percent={parseFloat(
                                            (applicant.receivedAmount /
                                              applicant.goalAmount) *
                                              100
                                          ).toFixed(2)}
                                          strokeColor="#FFAC1C"
                                        />
                                        <div class="goal-raised-container">
                                          <small className="ft-12 txt-dark goal">
                                            <i className="fas fa-chart-pie text-danger"></i>
                                            &nbsp; Goal: {applicant.goalAmount}
                                          </small>
                                          <small className="ft-12 txt-dark raised">
                                            <i className="fas fa-fist-raised text-green"></i>
                                            &nbsp;Raised:{" "}
                                            {applicant.receivedAmount}
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col">
                                        <div
                                          className=" btn-group-sm"
                                          role="group"
                                          aria-label="..."
                                        >
                                          <button
                                            type="button"
                                            class="ant-btn css-190m0jy ant-btn-default btn btn-secondary btn-sm custom-button"
                                            onClick={() =>
                                              userDonation(applicant)
                                            }
                                          >
                                            <i
                                              class="fa fa-handshake"
                                              aria-hidden="true"
                                            ></i>
                                            <span>&nbsp; Donate</span>
                                          </button>
                                          <button
                                            type="button"
                                            class="ant-btn css-190m0jy ant-btn-default btn btn-warning btn-sm custom-button"
                                            onClick={() =>
                                              dynamiccausesite(
                                                applicant.causeId,
                                                applicant.title
                                              )
                                            }
                                          >
                                            <i
                                              className="fa fa-eye"
                                              aria-hidden="true"
                                            ></i>
                                            <span>&nbsp;View</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <hr></hr>
                        </div>
                        <Pagination
                          Current={page}
                          total={totalElements}
                          pageSize={size}
                          onChange={handlePageChange}
                          showSizeChanger={false}
                        />
                      </>
                    )}
                    {tablelength == 0 && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "80vh" }}
                      >
                        <img
                          src={Nodata}
                          alt="Nodata"
                          className="alignimagecenter"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ListedCause;
